import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import BreadCrumbs from '../components/BreadCrumbs';
import ExpiringLease from '../components/ExpiringLease';
import ControlBar from '../components/ControlBar';
import API from '../services/API';
import { formatLeases, displayTeamProducer } from '../utils/lease-utils';
import { getParamsFromURL, storeParamsToURL } from '../utils/query-params';
import { EXPIRING_LEASES_FILTERS as filters } from '../constants/filters';
import {
  trackFilterClose,
  trackFilterOpen,
  trackSearch
} from '../services/event-tracking/expiring-leases-tracking';
import Alert from '../components/Alert';

const breadCrumbs = [
  {
    route: '/',
    name: 'Dashboard',
  },
  {
    route: '/expiring-leases/',
    name: 'Lease Expirations'
  }
];

const sortOptions = [
  { value: 'lease_expiration,asc', text: 'Lease Expiration ↑' },
  { value: 'lease_expiration,desc', text: 'Lease Expiration ↓' },
  { value: 'lease_start,asc', text: 'Lease Start ↑' },
  { value: 'lease_start,desc', text: 'Lease Start ↓' },
  { value: 'tot_comm,asc', text: 'Total Commission ↑' },
  { value: 'tot_comm,desc', text: 'Total Commission ↓' },
  { value: 'sqft,asc', text: 'Square Feet ↑' },
  { value: 'sqft,desc', text: 'Square Feet ↓' },
  { value: 'industry_type,asc', text: 'Property Type ↑' },
  { value: 'industry_type,desc', text: 'Property Type ↓' },
];

const ExpiringLeases = () => {
  const [leases, setLeases] = useState([]);
  const [params, setParams] = useState(getParamsFromURL({
    sort: 'lease_expiration',
    direction: 'asc'
  }));
  const [numResults, setNumResults] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLeases = useCallback(async () => {
    setIsLoading(true);
    storeParamsToURL(params);

    const response = await API.get('/api/expiring_leases/', { params });
    const responseCount = await API.get('/api/expiring_leases/count/', { params });
    setNumResults(responseCount.data.deals_count);
    const formattedLeases = formatLeases(response.data);

    displayTeamProducer(params.search, formattedLeases);
    setLeases(formattedLeases);
    setIsLoading(false);
  }, [params]);

  useEffect(() => {
    fetchLeases();
  }, [params, fetchLeases]);

  const loadMore = async () => {
    setIsLoading(true);
    const nextPageParams = {
      ...params,
      from: leases.length,
    };

    const response = await API.get('/api/expiring_leases/', { params: nextPageParams });
    const nextLeases = formatLeases(response.data);
    setLeases(leases.concat(nextLeases));
    setIsLoading(false);
  };

  const applyParams = (newParams) => {
    setParams({
      ...params,
      ...newParams,
    });
  };

  const search = (value) => {
    trackSearch(value);
    applyParams({ search: value || null });
  };

  const onSortChange = (evt) => {
    const [sort, direction] = evt.value.split(',');
    setParams({
      ...params,
      sort,
      direction,
    });
  };

  return (
    <React.Fragment>
      <BreadCrumbs crumbs={breadCrumbs} />
      <h2>Upcoming Lease Expirations {!!numResults && `(${numResults.toLocaleString()})`}</h2>
      {(numResults > 10000) && (
      <Alert
        alertDisplayProps="You can download only the first 10,000 Upcoming Lease Expirations. \
        If you need to download more, reduce the result size to 10,000 by using appropriate filters."
      />
      )}
      <ControlBar
        searchProps={{
          value: params.search || '',
          onChange: search,
          placeholder: 'Search by lessor/lessee or producer'
        }}
        filterProps={{
          filters,
          params,
          applyParams,
          onOpen: () => trackFilterOpen(params, numResults),
          onClose: () => trackFilterClose(params, numResults)
        }}
        sortProps={{
          value: `${params.sort},${params.direction}`,
          onChange: onSortChange,
          options: sortOptions
        }}
        downloadProps={{
          url: 'expiring_leases',
          getDownloadName: () => 'lease_expirations.csv',
          size: 10000,
        }}
        filterTagProps={{
          filters,
          params,
          applyParams,
        }}
      />
      <div className="expiring-leases-list">
        <InfiniteScroll
          initialLoad={false}
          loadMore={loadMore}
          hasMore={isLoading === false && leases.length < numResults}
        >
          {leases.map((lease) => {
            const { broker, _id: leaseId, _source: leaseSource } = lease;
            return (
              <ExpiringLease
                key={leaseId}
                lease={leaseSource}
                leadBroker={broker}
                refetchLease={fetchLeases}
              />
            );
          })}
          {!leases.length && !isLoading && (
            <div className="card">No leases found with current filters.</div>
          )}
          {isLoading && (
            <div className="card">Loading ... </div>
          )}
        </InfiniteScroll>
      </div>
    </React.Fragment>
  );
};

export default ExpiringLeases;
