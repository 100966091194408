import React from 'react';
import PropTypes from 'prop-types';

import './Alert.scss';

const Alert = ({ alertDisplayProps }) => {
  return (
    <div className="alert">
      {alertDisplayProps}
    </div>
  );
};

export default Alert;

Alert.propTypes = {
  alertDisplayProps: PropTypes.string.isRequired
};
