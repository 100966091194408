/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';

import LabeledItem from '../LabeledItem/index';

const BrokerTeam = ({ brokers, leadBrokerId }) => {
  const leadBroker = brokers.find((b) => (b.employee_id === leadBrokerId));
  const otherBrokers = brokers.filter((b) => (b.employee_id !== leadBrokerId));

  return (
    <div className="card">
      <h4 className="mb1">Producer Team</h4>
      {leadBroker  && (
        <LabeledItem
          label="Lead Producer"
          item={(
            <React.Fragment>
              <div>
                {leadBroker.first_name} {leadBroker.last_name}
              </div>
              <div className="subtext">{leadBroker.job_title}</div>
              <div className="subtext">{leadBroker.work_email}</div>
              <div className="subtext">{leadBroker.location_name}</div>
            </React.Fragment>
          )}
        />
      )}
      <div className="flex-row flex-wrap two-column">
        {otherBrokers.map((broker) => (
          <LabeledItem
            key={broker._id}
            label={broker.job_title}
            item={(
              <React.Fragment>
                <div>
                  {broker.first_name} {broker.last_name}
                </div>
                <div className="subtext">{broker.work_email}</div>
                <div className="subtext">{broker.location_name}</div>
              </React.Fragment>
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default BrokerTeam;

BrokerTeam.propTypes = {
  brokers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  leadBrokerId: PropTypes.string,
};

BrokerTeam.defaultProps = {
  leadBrokerId: null,
};
