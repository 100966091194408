/* eslint-disable jsx-a11y/anchor-is-valid */
/* global CONFIG */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Avatar from '../Avatar';
import Flyout from '../Flyout';

const UserMenu = ({ user }) => {
  const logOut = () => {
    window.location.replace(`${CONFIG.apiBaseURL}/oauth2/logout`);
  };

  return (
    <div className="topnav">
      <Flyout
        closeOnInsideClick
        align="right"
        trigger={
          <Avatar email={user.email} firstName={user.first_name} lastName={user.last_name} />
        }
        flyoutContent={(
          <div className="topnav-flyout flex-col">
            <NavLink to="/profile/">View Profile</NavLink>
            <a
              onClick={logOut}
              role="button"
            >
              Log Out
            </a>
          </div>
        )}
      />
    </div>
  );
};

UserMenu.propTypes = {
  user: PropTypes.object,
};

UserMenu.defaultProps = {
  user: {},
};

export default UserMenu;
