import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@cbrebuild/blocks';
import './Filters.scss';

// consts and utils
import { FILTER_ATTR_TYPES, FILTER_INPUT_TYPES } from '../../constants/filters';
import {
  getUpdatedParamValueForAttr,
  getValueFromFilterInput,
  getEmptyParamsForFilters
} from '../../utils/filters';

// input components
import Datepicker from '../Datepicker';
import MultiSelect from '../MultiSelect';

const { Input: { TextField, Toggle } } = require('@cbrebuild/blocks-react');

const NumberTextField = (props) => <TextField type="number" {...props} />;

const inputTypeToComponentMap = {
  date: Datepicker,
  number: NumberTextField,
  toggle: Toggle,
  multiSelect: MultiSelect,
};

const Filters = ({
  params: initParams,
  applyParams,
  filters,
  isSingleFilter,
  flyoutButtonRef,
}) => {
  const [params, setParams] = useState(initParams);

  useEffect(() => {
    setParams(initParams);
  }, [initParams]);

  const closeFlyout = () => {
    if (flyoutButtonRef) {
      flyoutButtonRef.current.click();
    }
  };

  const clearFilter = () => {
    const emptyParams = getEmptyParamsForFilters(filters);
    setParams(emptyParams);
  };

  const cancelFilter = () => {
    clearFilter();
    closeFlyout();
  };

  const applyFilter = () => {
    applyParams(params);
    closeFlyout();
  };

  const getFilterComponent = useCallback((filter) => {
    const {
      attr, // param keys
      attrType, // default, minMax, multipleChoice
      id,
      inputType, // toggle, number, date, multiSelect
      options, // options for select and toggles
      placeholder,
      selectedPlaceholder, // for select input
      hasSearch, // for select input
    } = filter;
    const InputComponent = inputTypeToComponentMap[inputType] || TextField;

    const handlefilterChange = (evtOptOrVal, filterAttr = attr) => {
      const newValue = getValueFromFilterInput(evtOptOrVal);
      const newParams = getUpdatedParamValueForAttr(filterAttr, attrType, newValue, params);
      setParams({
        ...params,
        ...newParams,
      });
      if (isSingleFilter) {
        applyParams({
          ...params,
          ...newParams,
        });
      }
    };

    const handleMinFilterChange = (evtOptOrVal) => {
      const filterAttr = attr[0];
      handlefilterChange(evtOptOrVal, filterAttr);
    };

    const handleMaxFilterChange = (evtOptOrVal) => {
      const filterAttr = attr[1];
      handlefilterChange(evtOptOrVal, filterAttr);
    };

    let inputValue = null;
    switch (attrType) {
      case FILTER_ATTR_TYPES.DEFAULT:
        inputValue = params[attr] || '';
        return (
          <InputComponent
            id={id}
            placeholder={placeholder}
            value={inputValue}
            onChange={handlefilterChange}
            options={options}
          />
        );
      case FILTER_ATTR_TYPES.MULTIPLE_CHOICE:
        // pass extra props for multiple choice input component
        inputValue = params[attr] ? params[attr].split(',') : [];
        return (
          <InputComponent
            id={id}
            text={placeholder || 'Select'} // this prop is only for the dropdown component
            selectedplaceholder={selectedPlaceholder} // only for dropdown to replace selected text
            options={options}
            value={inputValue}
            onChange={handlefilterChange}
            searchMultiSelect={hasSearch}
          />
        );
      case FILTER_ATTR_TYPES.MIN_MAX: {
        const minInput = params[attr[0]] || '';
        const maxInput = params[attr[1]] || '';
        return (
          <div className="flex-row align-center">
            <InputComponent
              value={minInput}
              onChange={handleMinFilterChange}
              label={inputType === FILTER_INPUT_TYPES.DATE ? 'Start' : 'Min'}
              placeholder="Min"
            />
            <hr className="min-max-dash" aria-hidden />
            <InputComponent
              value={maxInput}
              onChange={handleMaxFilterChange}
              label={inputType === FILTER_INPUT_TYPES.DATE ? 'End' : 'Max'}
              placeholder="Max"
            />
          </div>
        );
      }
      default: {
        throw new Error('Unrecognized filter attribute type', attrType);
      }
    }
  }, [applyParams, isSingleFilter, params]);

  if (isSingleFilter && filters[0]) {
    return (
      <div className="single-filter">
        {getFilterComponent(filters[0])}
      </div>
    );
  }

  return (
    <div className="filters">
      <div className="filter-content">
        {filters.map((filter) => (
          <div key={filter.id} className="filter-row">
            {filter.label && <div className="filter-label" htmlFor={filter.id}>{filter.label}</div>}
            {getFilterComponent(filter)}
          </div>
        ))}
      </div>
      <div className="filter-cta">
        <Button variant="text" onClick={clearFilter}>CLEAR FILTER</Button>
        <div>
          <Button variant="secondary" onClick={cancelFilter}>Cancel</Button>
          <Button variant="primary" onClick={applyFilter}>Apply</Button>
        </div>
      </div>
    </div>
  );
};

export default Filters;

Filters.propTypes = {
  applyParams: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      inputType: PropTypes.oneOf(
        Object.values(FILTER_INPUT_TYPES)
      ).isRequired,
      attr: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]).isRequired,
      attrType: PropTypes.oneOf(
        Object.values(FILTER_ATTR_TYPES)
      ).isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired,
  isSingleFilter: PropTypes.bool,
  flyoutButtonRef: PropTypes.shape({
    current: PropTypes.shape({
      click: PropTypes.func,
    })
  }),
};

Filters.defaultProps = {
  isSingleFilter: false,
  flyoutButtonRef: null,
};
