import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import BreadCrumbs from '../components/BreadCrumbs';
import Preferences from '../components/Preferences';
import ManagingOfficeAccess from '../components/ManagingOfficeAccess';
import UserContext from '../context/UserContext';
import Avatar from '../components/Avatar/index';
import './profile.scss';

const { Tabs, RadioTabItem } = require('@cbrebuild/blocks-react').Tabs;

const Profile = () => {
  const { user } = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState('preferences');

  const breadCrumbs = [
    {
      route: '/',
      name: 'Dashboard'
    },
    {
      route: '/profile/',
      name: 'Profile'
    }
  ];

  return (
    <div className="profile-page">
      <BreadCrumbs crumbs={breadCrumbs} />
      <h2>My Profile</h2>
      <div className="flex-row mb1">
        <Avatar email={user.email} firstName={user.first_name} lastName={user.last_name} />
        <div className="user-info">
          <h4>{user.first_name} {user.last_name}</h4>
          <div>{user.email}</div>
        </div>
      </div>
      <Tabs>
        <RadioTabItem
          key="horizontal-tabs-2"
          name="profile-page-tabs"
          value="preferences"
          checked={selectedTab === 'preferences'}
          onChange={(e) => setSelectedTab(e.target.value)}
          text="Preferences"
        />
        <RadioTabItem
          key="horizontal-tabs-3"
          name="profile-page-tabs"
          value="managingOffice"
          checked={selectedTab === 'managingOffice'}
          onChange={(e) => setSelectedTab(e.target.value)}
          text="Managing Office Access"
        />
      </Tabs>
      <section className="card profile-card">
        {selectedTab === 'preferences' && (
          <Preferences />
        )}
        {selectedTab === 'managingOffice' && (
          <ManagingOfficeAccess />
        )}
      </section>
      <div className="links">
        <Link to="/terms">Terms of Service</Link>
        <Link to="/privacy">Privacy Policy</Link>
      </div>
    </div>
  );
};

export default Profile;
