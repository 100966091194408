import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Textarea } from '@cbrebuild/blocks';
import API from '../../services/API';
import './LeaseNotes.scss';

const LeaseNotes = ({ leaseId, refetchLease }) => {
  const [newNoteText, setNewNoteText] = useState('');

  const saveNewNote = async () => {
    const payload = {
      deal_id: leaseId,
      body: newNoteText,
    };

    await API.post('/api/expiring_leases/notes/', payload);
    refetchLease();
    setNewNoteText('');
  };

  return (
    <div className="card lease-notes">
      <h4>Notes</h4>
      <Textarea
        value={newNoteText}
        onChange={(e) => { setNewNoteText(e.target.value); }}
        placeholder="Add a note to Action History..."
        rows={{
          min: 4,
          max: Infinity,
        }}
      />
      {newNoteText && (
        <div className="flex-row flex-end">
          <Button onClick={saveNewNote}>Save</Button>
        </div>
      )}
    </div>
  );
};

export default LeaseNotes;

LeaseNotes.propTypes = {
  leaseId: PropTypes.string.isRequired,
  refetchLease: PropTypes.func.isRequired,
};
