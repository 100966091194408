/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Checkbox, Icon } from '@cbrebuild/blocks';
import { currencyFormat, dateFormat, percentFormat } from './formatters';
import { InlineEdit } from '../components/InlineEdit';

const EMPTY_CELL_PLACEHOLDER = '--';
const REQUIRED_CELL_PLACEHOLDER = '*Missing';

const SelectHeader = () => <Icon iconName="sort" size="medium" className="select-header-icon" />;

const CheckboxRenderer = (props) => {
  const {
    value: initialValue,
    row: { id: rowId, index },
    updateData,
    column: { id, key },
  } = props;
  const [value, setValue] = useState(initialValue);

  const updateKey = key || id;
  const handleChange = (e) => {
    setValue(e.target.checked);
    updateData(e.target.checked, updateKey, index);
  };

  const checkboxProps = {
    onChange: handleChange,
    checked: !!value,
  };

  return <Checkbox {...checkboxProps} id={`select-row-${rowId}`} />;
};

const DateRenderer = (props) => {
  const {
    value,
    column: { isRequired },
  } = props;
  const emptyState = isRequired ? REQUIRED_CELL_PLACEHOLDER : EMPTY_CELL_PLACEHOLDER;
  return <span>{value ? dateFormat(value) : emptyState}</span>;
};

const CurrencyRenderer = (props) => {
  const {
    value,
    column: { isRequired },
  } = props;
  const emptyState = isRequired ? REQUIRED_CELL_PLACEHOLDER : EMPTY_CELL_PLACEHOLDER;
  return <span>{value ? currencyFormat(value) : emptyState}</span>;
};

const PercentRenderer = (props) => {
  const {
    value,
    column: { isRequired },
  } = props;
  const emptyState = isRequired ? REQUIRED_CELL_PLACEHOLDER : EMPTY_CELL_PLACEHOLDER;
  return <span>{value ? percentFormat(value) : emptyState}</span>;
};

const InlineEditRenderer = (props) => {
  // this should not maintain inlineEdit's state
  // this should only handle table specific values
  // to generate props for inlineEdit in the table

  const {
    value,
    row: { index },
    column: { id, inputType },
    updateData,
  } = props;

  const handleUpdate = (v) => {
    updateData(v, id, index);
  };

  return (
    <InlineEdit
      inputType={inputType}
      id={id}
      value={value}
      update={handleUpdate}
    />
  );
};

const QuickViewRenderer = (props) => {
  const {
    value,
    row: { original },
    toggleQuickView,
  } = props;

  const handleToggleQuickView = () => {
    toggleQuickView(original);
  };

  return (
    <span onClick={handleToggleQuickView}>{value}</span>
  );
};

const WithQuickView = (Component) => (props) => {
  const {
    row: { original },
    toggleQuickView,
  } = props;

  const handleToggleQuickView = () => {
    toggleQuickView(original);
  };

  return (
    <span onClick={handleToggleQuickView} className="text-button">
      <Component {...props} />
    </span>
  );
};

export {
  EMPTY_CELL_PLACEHOLDER,
  REQUIRED_CELL_PLACEHOLDER,
  SelectHeader,
  CheckboxRenderer,
  DateRenderer,
  CurrencyRenderer,
  InlineEditRenderer,
  QuickViewRenderer,
  WithQuickView,
  PercentRenderer,
};
