/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import './Switch.scss';

const Switch = ({ checked, label, onChange, disabled }) => {
  return (
    <div className="switch-container">
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
        />
        <div className="slider" />
      </label>
      <label>{label}</label>
    </div>
  );
};

export default Switch;

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Switch.defaultProps = {
  label: '',
};
