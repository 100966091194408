import React from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import { Icon } from '@cbrebuild/blocks';
import 'react-day-picker/lib/style.css';
import './Datepicker.scss';

const Datepicker = ({ onChange, value, label }) => {
  // format how a selected date will be displayed in the input
  const formatDate = (date) => (moment(date).format('M/D/YYYY'));

  // Parse input as user types to create a JS date object
  // once user has entered a valid date string with format: 'M/D/YYYY'
  const parseDate = (date) => {
    const m = moment(date, 'M/D/YYYY', 'en', true);
    return m.isValid() ? m.toDate() : undefined;
  };

  // convert date object to string format ('YYYY-MM-DD') and then send to parent
  const onDayChange = (date, modifiers, dayPickerInput) => {
    const input = dayPickerInput.getInput();
    if (!input.value) {
      // update input as null when user clears the input
      onChange(null);
    } else if (date) {
      // date exists only when input date is valid;
      const updatedDate = date ? moment(date).format('YYYY-MM-DD') : '';
      onChange(updatedDate);
    }
  };

  return (
    <div className="datepicker-container">
      {label && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className="blx-ui-text">
          {label}
        </label>
      )}
      <DayPickerInput
        value={value ? moment(value).toDate() : ''}
        onDayChange={onDayChange}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder="MM/DD/YYYY"
      />
      <Icon iconName="calendar" />
    </div>
  );
};

export default Datepicker;

Datepicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string
};

Datepicker.defaultProps = {
  value: '',
  label: ''
};
