/* eslint-disable max-len */
const FILTER_ATTR_TYPES = {
  DEFAULT: 'default', // basic attribute string
  MIN_MAX: 'minMax', // array of [minAttr, maxAttr] which can be searched separately or together
  MULTIPLE_CHOICE: 'multipleChoice', // One attribute that takes array as value
};

const FILTER_INPUT_TYPES = {
  TOGGLE: 'toggle',
  NUMBER: 'number',
  DATE: 'date',
  MULTI_SELECT: 'multiSelect',
};

const EXPIRING_LEASES_FILTERS = [
  {
    id: 'state',
    attr: 'state',
    attrType: FILTER_ATTR_TYPES.DEFAULT,
    inputType: FILTER_INPUT_TYPES.TOGGLE,
    options: [
      { text: 'All', value: '' },
      { text: 'Needs Attention', value: 'undelegated' },
      { text: 'Delegated', value: 'delegated' },
    ],
    label: 'Select Delegation State',
    hideTagLabel: true, // requested by design as "state" label is vague and not helpful
  },
  {
    id: 'expirationDateRange',
    attr: ['expires_from', 'expires_to'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.DATE,
    label: 'Expiration Date'
  },
  {
    id: 'sqftRange',
    attr: ['sqft_from', 'sqft_to'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.NUMBER,
    label: 'SQFT'
  },
  {
    id: 'commissionRange',
    attr: ['tot_comm_from', 'tot_comm_to'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.NUMBER,
    label: 'Total Commission'
  },
  {
    id: 'repRole',
    attr: 'rep_roles',
    attrType: FILTER_ATTR_TYPES.DEFAULT,
    inputType: FILTER_INPUT_TYPES.TOGGLE,
    options: [
      { text: 'All', value: '' },
      { text: 'Landlord Lease', value: 'L' },
      { text: 'Tenant Lease', value: 'T' },
    ],
    label: 'Rep Role'
  },
  {
    id: 'industryType',
    attr: 'industry_type',
    attrType: FILTER_ATTR_TYPES.DEFAULT,
    inputType: FILTER_INPUT_TYPES.TOGGLE,
    options: [
      { text: 'All', value: '' },
      { text: 'Office', value: 'Office' },
      { text: 'Industrial', value: 'Industrial' },
      { text: 'Retail', value: 'Retail' },
    ],
    label: 'Property Type'
  }
];

const PIPELINE_SUP_FILTER = {
  id: 'managingOffices',
  attr: 'fumo_employee_filters',
  attrType: FILTER_ATTR_TYPES.MULTIPLE_CHOICE,
  inputType: FILTER_INPUT_TYPES.MULTI_SELECT,
  options: [], // dynamic options per user
  tagLabel: '',
  label: 'Producers by Managing Office',
  placeholder: 'Producers by Managing Office',
  selectedPlaceholder: 'Producers selected',
  hasSearch: true,
};

const PIPELINE_FILTERS = [
  {
    id: 'stage',
    attr: 'stage',
    attrType: FILTER_ATTR_TYPES.MULTIPLE_CHOICE,
    inputType: FILTER_INPUT_TYPES.MULTI_SELECT, // temporarily use multiselect until blocks v2 group button is created.
    options: [
      { text: 'All', value: 'Prospecting,Executing,Closed' },
      { text: 'Prospecting', value: 'Prospecting' },
      { text: 'Executing', value: 'Executing' },
      { text: 'Closed', value: 'Closed' }
    ],
    label: 'Deal Stage'
  },
  {
    id: 'dealTypeRepRole',
    attr: 'rep_roles',
    attrType: FILTER_ATTR_TYPES.MULTIPLE_CHOICE,
    inputType: FILTER_INPUT_TYPES.MULTI_SELECT,
    options: [
      { text: 'All', value: 'Tenant,Landlord,Buyer,Seller,Consulting,Other' },
      { text: 'Lease - Tenant', value: 'Tenant' },
      { text: 'Lease - Landlord', value: 'Landlord' },
      { text: 'Sale - Buyer', value: 'Buyer' },
      { text: 'Sale - Seller', value: 'Seller' },
      { text: 'Consulting', value: 'Consulting' },
      { text: 'Other', value: 'Other' }
    ],
    label: 'Deal Type - Rep Role'
  },
  {
    id: 'commissionRange',
    attr: ['tot_comm_from', 'tot_comm_to'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.NUMBER,
    label: 'CBRE Gross Commission'
  },
  {
    id: 'commissionOverrideRange',
    attr: ['commission_override_from', 'commission_override_to'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.NUMBER,
    label: 'CBRE Gross Commission Override',
  },
  {
    id: 'probabilityRange',
    attr: ['conversion_from', 'conversion_to'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.NUMBER,
    label: 'Probability',
  },
  {
    id: 'probabilityOverrideRange',
    attr: ['conversion_override_from', 'conversion_override_to'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.NUMBER,
    label: 'Probability Override',
  },
  {
    id: 'installmentDate',
    attr: ['first_installment_date_from', 'first_installment_date_to'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.DATE,
    label: 'Installment Date'
  },
  {
    id: 'installmentDateOverride',
    attr: ['first_installment_date_from_override', 'first_installment_date_to_override'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.DATE,
    label: 'Installment Date Override'
  },
  {
    id: 'dealAddedDate',
    attr: ['date_added_from', 'date_added_to'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.DATE,
    label: 'Deal Added Date'
  },
  {
    id: 'lastUpdatedDate',
    attr: ['last_updated_from', 'last_updated_to'],
    attrType: FILTER_ATTR_TYPES.MIN_MAX,
    inputType: FILTER_INPUT_TYPES.DATE,
    label: 'Last Updated (Pipeline) Date'
  },
  {
    id: 'accountingData',
    attr: 'accounting_data',
    attrType: FILTER_ATTR_TYPES.DEFAULT,
    inputType: FILTER_INPUT_TYPES.TOGGLE,
    options: [{
      text: 'All',
      value: ''
    }, {
      text: 'Deals - Not Vouchered (MTA)',
      value: 'non_mta_only'
    }, {
      text: 'Deals - Vouchered (MTA)',
      value: 'mta_only'
    }],
    label: 'MTA Deals'
  },
  {
    id: 'dataValidity',
    attr: 'is_valid',
    attrType: FILTER_ATTR_TYPES.DEFAULT,
    inputType: FILTER_INPUT_TYPES.TOGGLE,
    options: [{
      text: 'All',
      value: ''
    }, {
      text: 'Deals with complete data',
      value: 'true'
    }, {
      text: 'Deals with incomplete data',
      value: 'false'
    }],
    label: 'Pipeline Data Validity'
  },
];

export {
  FILTER_ATTR_TYPES,
  FILTER_INPUT_TYPES,
  EXPIRING_LEASES_FILTERS,
  PIPELINE_FILTERS,
  PIPELINE_SUP_FILTER,
};
