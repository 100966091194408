/* eslint-disable no-param-reassign */
// warning: will mutate data
const formatLeases = (data) => {
  data.actions.forEach((action) => {
    const delegate = data.brokers.data && data.brokers.data.searchEmployeeDetailsES.employee.find((broker) => broker.employee_id === action.delegate);
    if (delegate) {
      action.delegate = delegate;
    }
    // attach last action to associated lease
    const lease = data.deals.hits.hits.find((deal) => deal._id === action.deal_id);
    if (!lease._source.lastAction) {
      lease._source.lastAction = action;
    }
  });
  // attach related broker objects to each lease object as well as to each lease.dealline object
  return data.deals.hits.hits.map((lease) => {
    if (lease._source.dealline) { // every now and then, a lease will not have a dealline
      lease._source.dealline.forEach((line) => {
        const broker = data.brokers.data && data.brokers.data.searchEmployeeDetailsES.employee.find((b) => (b.employee_id === line.emplid));
        line.employee = broker || {};
      });
    }
    const leadBroker = data.brokers.data && data.brokers.data.searchEmployeeDetailsES.employee.find(
      (broker) => (broker.employee_id === lease._source.lead_broker)
    );

    return {
      ...lease,
      broker: leadBroker || {},
    };
  });
};

// display a team producer (normally hidden in list view)
// when a result is yielded by team producer name search match
const displayTeamProducer = (searchString, leases) => {
  if (!searchString || searchString.length < 3) {
    return;
  }

  leases.forEach((lease) => {
    for (const line of lease._source.dealline) {
      const { employee } = line;
      if (employee && employee.employee_id !== lease._source.lead_broker) {
        const firstName = employee.first_name || '';
        const lastName = employee.last_name || '';
        const fullName = (firstName + lastName).toLowerCase();
        const formattedSearch = searchString.toLowerCase().replace(/\s/g, '');

        if (fullName.includes(formattedSearch)) {
          // warning: mutates lease object
          lease._source.searchMatchedProducer = employee;
          break;
        }
      }
    }
  });
};

export {
  formatLeases,
  displayTeamProducer,
};
