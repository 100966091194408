/* eslint-disable max-len */
import { sortBy } from 'lodash';
import API from './API';
import { addSelectAllForMultiSelect } from '../utils/filters';
import { fillOverrides } from '../utils/object-utils';

// use temporary mock response
// import employeesByFumo from '../__mocks__/employees_by_fumo_response.json';
// import pipelineMock from '../__mocks__/pipeline_response.json';

const url = '/api/pipeline/';
const auditUrl = '/api/pipeline/audit';
const aggregateUrl = '/api/pipeline/aggregates/';
const overridesHistoryUrl = '/api/pipeline/get_overrides_history';

let cachedFMC = null;

// only overriderUrl is writable.
const overrideUrl = '/api/pipeline/override';

const fetchPipelineAggregates = (params, cancelToken) => {
  return API.post(aggregateUrl, { params }, { cancelToken })
    .then((res) => {
      return res.data.pipeline_aggs;
    })
    .catch((err) => {
      if (API.isCancel(err)) {
        console.log('Pipeline Aggregates Fetch cancelled', err.message);
      } else {
        console.error(err);
      }
    });
};

const fetchPipeline = (params, cancelToken) => {
  return API.post(url, { params }, { cancelToken })
    .then((res) => {
      const data = res.data.pipeline_deals;

      for (const deal of data.results) {
        if (deal.commissions) { // temporary handling for DealIQ syntax
          deal.commission = deal.commissions;
          deal.client_name = deal.client__name;
          deal.added_by_first_name = deal.added_to_pipeline_by__first_name;
          deal.added_by_last_name = deal.added_to_pipeline_by__last_name;
          deal.added_to_pipeline = deal.added_to_pipeline_date;
          deal.last_updated_for_pipeline = deal.pipeline_modified_datetime;
          deal.pipeline_comments = deal.pipeline_report_comments;
          for (const c of deal.commission) {
            c.is_lead = c.broker_is_lead;
            c.functional_unit_name = c.broker__functional_unit_name;
            c.functional_unit = c.broker__functional_unit_code;
            c.managing_office_name = c.broker__managing_office_name;
            c.managing_office = c.broker__managing_office_code;
          }
          deal.stage = deal.deal_life_cycle_stage;
        }

        deal.overrides = fillOverrides(deal.overrides);
      }

      return data;
      // test data for longer list
      // return pipelineMock.pipeline_deals;
    })
    .catch((err) => {
      if (API.isCancel(err)) {
        console.log('Pipeline Fetch canceled', err.message);
        throw err; // throw error for pipeline batched fetch can catch the error.
      } else {
        console.error(err);
      }
    });
};

const fetchOverridesHistory = (dealId) => {
  return API.get(`${overridesHistoryUrl}/${dealId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.error(err));
};

const fetchDIQAuditInfo = (dealId) => {
  return API.get(`${auditUrl}/${dealId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.error(err));
};

const updatePipeline = (payload) => {
  return API.patch(overrideUrl, payload)
    .then((res) => {
      return res;
    });
  // exclude error handler here so any error gets passed on to updateOrCreate, where this is called
};

const createPipeline = (payload) => {
  return API.post(overrideUrl, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

const updateOrCreate = (payload) => {
  return updatePipeline(payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.data === 'Invalid id value') {
        createPipeline(payload);
      } else {
        console.error(err);
      }
    });
};

const formatFMCOptions = (employeesByFumo) => {
  const brokersOptions = [];
  const sortedFumo = sortBy(employeesByFumo, ['functional_unit_name', 'managing_office_name']);
  sortedFumo.forEach((fumo) => {
    const brokers = fumo.brokers.map((broker) => {
      const source = broker;
      source.value = `${source.functional_unit_code}-${source.managing_office_code}-${source.employee_id}`;
      return source;
    });
    const groupValue = (brokers.map((broker) => broker.value)).join(',');
    brokersOptions.push({
      key: `${fumo.fumo}`,
      value: groupValue,
      text: `${fumo.functional_unit_name}(${fumo.functional_unit_code}) / ${fumo.managing_office_name}(${fumo.managing_office_code})`,
      groupKey: `${fumo.fumo}`,
      isGroupHeader: true,
    });

    if (brokers.length) {
      const sortedBrokers = sortBy(brokers, ['first_name', 'middle_name', 'last_name']);
      sortedBrokers.forEach((broker) => {
        brokersOptions.push({
          key: `${broker.fumo}-${broker.employee_id}`,
          value: broker.value,
          text: `${broker.first_name} ${broker.last_name}`,
          subtext: broker.work_email,
          groupKey: `${fumo.fumo}`,
          isNested: true,
        });
      });
    }
  });

  return brokersOptions;
};

const getFMCOptions = () => {
  if (cachedFMC) {
    return Promise.resolve(cachedFMC);
  }
  return API.get('/api/employees_by_fumo/')
    .then((res) => {
      const fmcOptions = addSelectAllForMultiSelect(formatFMCOptions(res.data.employees_by_fumo));
      cachedFMC = fmcOptions;
      return (fmcOptions);
      // use mock response below for local test
      // until we have testable data locally.
      // return employeesByFumo.employees_by_fumo;
    })
    .catch((err) => {
      console.error(err);
    });
};

export default {
  fetchPipelineAggregates,
  fetchPipeline,
  fetchOverridesHistory,
  fetchDIQAuditInfo,
  updateOrCreate,
  getFMCOptions,
};
