import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@cbrebuild/blocks';

const DealNameCell = ({ row: { original: deal } }) => {
  return (
    <React.Fragment>
      {!deal.is_valid_for_pipeline_report &&
        <Icon iconName="warning" className="invalid-icon" />}
      {deal.name}
    </React.Fragment>
  );
};

DealNameCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      is_valid_for_pipeline_report: PropTypes.bool
    }).isRequired
  }).isRequired
};

export default DealNameCell;
