import React from 'react';
import PropTypes from 'prop-types';
import API from '../../services/API';
import Autocomplete from '../Autocomplete';

const EmployeeAutocomplete = ({ onSelect }) => {
  const search = async (text) => {
    const params = {
      query: text,
      size: 100,
      employment_status: 'Active',
    };

    const response = await API.get('/api/employee/', { params });
    // eslint-disable-next-line
    return response.data.data.searchEmployeeDetailsES.employee;
  };

  const formatSuggestion = (suggestion) => (
    <React.Fragment>
      <div>{suggestion.first_name} {suggestion.last_name}</div>
      <div className="subtext">{suggestion.job_title}</div>
      <div className="subtext">{suggestion.work_email}</div>
      <div className="subtext">{suggestion.location_name}</div>
    </React.Fragment>
  );

  return (
    <Autocomplete
      search={search}
      onSelect={onSelect}
      formatSuggestion={formatSuggestion}
      suggestionKey="employee_id"
      placeholder="Search employees"
    />
  );
};

export default EmployeeAutocomplete;

EmployeeAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
};
