/* eslint-disable max-len */
import { INPUT_TYPES } from '../components/InlineEdit';
import DealNameCell from '../components/DealNameCell';
import {
  EMPTY_CELL_PLACEHOLDER,
  SelectHeader,
  CheckboxRenderer,
  DateRenderer,
  CurrencyRenderer,
  InlineEditRenderer,
  WithQuickView,
  PercentRenderer,
} from '../utils/table-cell';

import {
  getLeadProducerName,
  getProducerTeam,
  getManagingOffices,
  getCollaborators
} from '../utils/pipeline-table';

const EXECUTING = 'Executing';

const PIPELINE_COLUMNS = {
  DEAL_NAME: 'deal_name',
  DEAL_IQ_ID: 'deal_iq_id',
  MTA_ID: 'mta_id',
  CLIENT_NAME: 'client_name',
  PROPERTY: 'property',
  LEAD_PRODUCER: 'lead_producer',
  PRODUCER_TEAM: 'producer_team',
  GROSS_COMMISSION: 'gross_commission',
  GROSS_COMMISSION_OVERRIDE: 'total_commission_to_cbre_override',
  PROBABILITY: 'probability',
  PROBABILITY_OVERRIDE: 'conversion_potential_override',
  DEAL_STAGE: 'deal_stage',
  INSTALLMENT_DATE: 'installment_date',
  INSTALLMENT_DATE_OVERRIDE: 'installment_date_override',
  DEAL_TYPE: 'deal_type',
  PROPERTY_TYPE: 'property_type',
  DEAL_ADDED_DATE: 'added_to_pipeline',
  DEAL_ADDED_BY: 'added_by',
  LAST_UPDATED_FOR_PIPELINE: 'last_updated_for_pipeline',
  FUMO: 'function_unit_managing_office',
  COMMENTS: 'pipeline_comments',
  IS_ON_PIPELINE: 'is_on_pipeline_override',
  COLLABORATORS: 'deal_collaborators',
  MANAGER_COMMENTS: 'manager_comments',
};

// each data object needs string id or string header
const PIPELINE_COLUMN_DATA = {
  [PIPELINE_COLUMNS.IS_ON_PIPELINE]: {
    id: PIPELINE_COLUMNS.IS_ON_PIPELINE,
    Header: SelectHeader,
    accessor: (deal) => {
      // No override -> by default, on pipeline
      if (deal.overrides.is_on_pipeline_override.value == null) {
        return 1;
      }
      return deal.overrides.is_on_pipeline_override.value ? 1 : 0;
    },
    key: PIPELINE_COLUMNS.IS_ON_PIPELINE,
    Cell: CheckboxRenderer,
    isPermanent: true,
    width: 80,
  },
  [PIPELINE_COLUMNS.DEAL_NAME]: {
    Header: 'Deal Name',
    accessor: 'name',
    Cell: WithQuickView(DealNameCell),
    className: 'name',
    isPermanent: true,
    width: 290,
  },
  [PIPELINE_COLUMNS.DEAL_IQ_ID]: {
    Header: 'Deal IQ ID',
    accessor: (deal) => (deal.linked_deal_iq_id || deal.deal_iq_id),
    className: 'deal-iq-id',
    width: 120,
  },
  [PIPELINE_COLUMNS.MTA_ID]: {
    Header: 'MTA ID',
    accessor: (deal) => (deal.linked_deal_ids && deal.linked_deal_ids.length)
      ? deal.linked_deal_ids.join(', ')
      : EMPTY_CELL_PLACEHOLDER,
    width: 130,
  },
  [PIPELINE_COLUMNS.CLIENT_NAME]: {
    Header: 'Client',
    accessor: (deal) => (deal.client_name || EMPTY_CELL_PLACEHOLDER),
  },
  [PIPELINE_COLUMNS.PROPERTY]: {
    Header: 'Property',
    accessor: (deal) => (deal.property || EMPTY_CELL_PLACEHOLDER),
  },
  [PIPELINE_COLUMNS.LEAD_PRODUCER]: {
    Header: 'Lead Producer',
    accessor: getLeadProducerName,
    width: 165,
  },
  [PIPELINE_COLUMNS.PRODUCER_TEAM]: {
    Header: 'Producer Team',
    accessor: (deal) => (deal.commission.length > 0) && (deal.commission.filter((person) => {
      return person.full_name === undefined;
    }).length === 0) ? getProducerTeam(deal) : '*Missing',
  },
  [PIPELINE_COLUMNS.GROSS_COMMISSION]: {
    Header: 'CBRE Gross Commission',
    accessor: (deal) => deal.is_mta ? deal.total_gross_commission : deal.estimated_commission,
    Cell: CurrencyRenderer,
    isRequired: true,
    rightAlign: true,
    width: 170,
  },
  [PIPELINE_COLUMNS.GROSS_COMMISSION_OVERRIDE]: {
    Header: 'CBRE Gross Commission Override',
    id: 'total_commission_to_cbre_override',
    accessor: 'overrides.total_commission_to_cbre_override.value',
    Cell: InlineEditRenderer,
    inputType: INPUT_TYPES.CURRENCY,
    rightAlign: true,
    className: 'com-override',
    width: 180,
  },
  [PIPELINE_COLUMNS.PROBABILITY]: {
    Header: 'Probability',
    accessor: (deal) => deal.conversion_potential,
    Cell: PercentRenderer,
    rightAlign: true,
    width: 130,
  },
  [PIPELINE_COLUMNS.PROBABILITY_OVERRIDE]: {
    Header: 'Prob Override',
    id: 'conversion_potential_override',
    accessor: 'overrides.conversion_potential_override.value',
    Cell: InlineEditRenderer,
    inputType: INPUT_TYPES.PERCENT,
    rightAlign: true,
    className: 'prob-override',
    width: 130,
  },
  [PIPELINE_COLUMNS.DEAL_STAGE]: {
    Header: 'Deal Stage',
    accessor: (deal) => `${deal.stage} ${deal.stage === EXECUTING && deal.substage
      ? `- ${deal.substage}`
      : ''}`,
    width: 150,
  },
  [PIPELINE_COLUMNS.INSTALLMENT_DATE]: {
    Header: 'Installment Date',
    accessor: 'first_installment_date',
    width: 155,
    isRequired: true,
    Cell: DateRenderer,
    rightAlign: true,
  },
  [PIPELINE_COLUMNS.INSTALLMENT_DATE_OVERRIDE]: {
    Header: 'Installment Date Override',
    id: 'installment_date_override',
    accessor: 'overrides.installment_date_override.value',
    Cell: InlineEditRenderer,
    inputType: INPUT_TYPES.DATE,
    rightAlign: true,
    ellipsis: false,
  },
  [PIPELINE_COLUMNS.DEAL_TYPE]: {
    Header: 'Deal Type',
    accessor: (deal) => `${deal.deal_type} - ${deal.rep_role}`,
  },
  [PIPELINE_COLUMNS.PROPERTY_TYPE]: {
    Header: 'Property Type',
    accessor: (deal) => `${deal.property_type || EMPTY_CELL_PLACEHOLDER}`,
  },
  [PIPELINE_COLUMNS.DEAL_ADDED_DATE]: {
    Header: 'Deal Added',
    accessor: 'added_to_pipeline',
    width: 110,
    rightAlign: true,
    Cell: DateRenderer,
  },
  [PIPELINE_COLUMNS.DEAL_ADDED_BY]: {
    Header: 'Added By',
    accessor: (deal) => deal.added_by_first_name && deal.added_by_last_name
      ? `${deal.added_by_first_name} ${deal.added_by_last_name}`
      : EMPTY_CELL_PLACEHOLDER,
    width: 150,
  },
  [PIPELINE_COLUMNS.LAST_UPDATED_FOR_PIPELINE]: {
    Header: 'Last Updated (Pipeline)',
    accessor: 'last_updated_for_pipeline',
    width: 110,
    rightAlign: true,
    Cell: DateRenderer,
  },
  [PIPELINE_COLUMNS.FUMO]: {
    Header: 'Functional Unit / Managing Office',
    accessor: getManagingOffices,
    width: 260,
  },
  [PIPELINE_COLUMNS.COMMENTS]: {
    Header: 'Deal Comments',
    accessor: (deal) => (deal.pipeline_comments || EMPTY_CELL_PLACEHOLDER),
    width: 200,
  },
  [PIPELINE_COLUMNS.COLLABORATORS]: {
    Header: 'Deal Collaborators',
    accessor: getCollaborators,
    width: 200,
  },
  [PIPELINE_COLUMNS.MANAGER_COMMENTS]: {
    Header: 'Manager Comments',
    id: 'manager_comments',
    accessor: 'overrides.manager_comments.value',
    Cell: InlineEditRenderer,
    inputType: INPUT_TYPES.TEXT,
    width: 250,
  },
};

const DEFAULT_PIPELINE_TABLE_CONFIG = [
  { id: PIPELINE_COLUMNS.IS_ON_PIPELINE, isPermanent: true },
  { id: PIPELINE_COLUMNS.DEAL_NAME, isPermanent: true },
  { id: PIPELINE_COLUMNS.DEAL_IQ_ID },
  { id: PIPELINE_COLUMNS.MTA_ID },
  { id: PIPELINE_COLUMNS.CLIENT_NAME },
  { id: PIPELINE_COLUMNS.PROPERTY },
  { id: PIPELINE_COLUMNS.LEAD_PRODUCER },
  { id: PIPELINE_COLUMNS.PRODUCER_TEAM },
  { id: PIPELINE_COLUMNS.COLLABORATORS },
  { id: PIPELINE_COLUMNS.GROSS_COMMISSION },
  { id: PIPELINE_COLUMNS.GROSS_COMMISSION_OVERRIDE },
  { id: PIPELINE_COLUMNS.PROBABILITY },
  { id: PIPELINE_COLUMNS.PROBABILITY_OVERRIDE },
  { id: PIPELINE_COLUMNS.DEAL_STAGE },
  { id: PIPELINE_COLUMNS.INSTALLMENT_DATE },
  { id: PIPELINE_COLUMNS.INSTALLMENT_DATE_OVERRIDE },
  { id: PIPELINE_COLUMNS.DEAL_TYPE },
  { id: PIPELINE_COLUMNS.PROPERTY_TYPE },
  { id: PIPELINE_COLUMNS.DEAL_ADDED_DATE },
  { id: PIPELINE_COLUMNS.DEAL_ADDED_BY },
  { id: PIPELINE_COLUMNS.LAST_UPDATED_FOR_PIPELINE },
  { id: PIPELINE_COLUMNS.FUMO },
  { id: PIPELINE_COLUMNS.COMMENTS },
  { id: PIPELINE_COLUMNS.MANAGER_COMMENTS },
];

export {
  PIPELINE_COLUMNS,
  PIPELINE_COLUMN_DATA,
  DEFAULT_PIPELINE_TABLE_CONFIG
};
