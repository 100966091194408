import React from 'react';
import PropTypes from 'prop-types';

import './Tag.scss';
import { IconButton } from '@cbrebuild/blocks';

const Tag = ({ label, smallLabel, fumo_code, onClose }) => {
  return (
    <div className="tag">
      <div className="label-container">
        <p>{label}</p>
        <p className="small-label">{smallLabel}</p>
      </div>
      <IconButton iconName="close" onClick={() => onClose(fumo_code)} />
    </div>
  );
};

export default Tag;

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  smallLabel: PropTypes.string,
  fumo_code: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

Tag.defaultProps = {
  smallLabel: '',
};
