/* eslint-disable max-len */
import React from 'react';

import './Privacy.scss';

const Privacy = () => (
  <div className="privacy-page">
    <h2>Performance IQ Privacy Policy</h2>
    <div className="card">
      <h4>Introduction</h4>
      <p>Performance IQ is part of CBRE which includes CBRE Build. This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.</p>

      <h4>Topics</h4>
      <ul>
        <li><a href="#1">What data do we collect?</a></li>
        <li><a href="#2">How do we collect your data?</a></li>
        <li><a href="#3">How will we use your data?</a></li>
        <li><a href="#4">How do we store your data?</a></li>
        <li><a href="#5">Marketing</a></li>
        <li><a href="#6">What are your data protection rights?</a></li>
        <li><a href="#7">What are cookies?</a></li>
        <li><a href="#8">How do we use cookies?</a></li>
        <li><a href="#9">What types of cookies do we use?</a></li>
        <li><a href="#10">How to manage your cookies</a></li>
        <li><a href="#11">Changes to our privacy policy</a></li>
        <li><a href="#12">How to contact us</a></li>
      </ul>

      <h4 id="1">What data do we collect?</h4>
      <p>Performance IQ collects the following data:</p>
      <ul>
        <li>Personal identification information (Name, email address)</li>
      </ul>

      <h4 id="2">How do we collect your data?</h4>
      <p>We collect data and process data when you:</p>
      <ul>
        <li>Register to use our application.</li>
        <li>Voluntarily submit data in our application.</li>
        <li>Use or view our application via your browser's cookies.</li>
      </ul>

      <h4 id="3">How will we use your data?</h4>
      <p>Performance IQ collects your data so that we can:</p>
      <ul>
        <li>Email you with reports and other notifications.</li>
        <li>Manage your account preferences.</li>
      </ul>
      <p>We may share your personal information with companies acting as our authorized agents in providing our services, including the following categories of service provider:</p>
      <ul>
        <li>Infrastructure and IT service providers, including cloud service providers.</li>
        <li>Providers of website testing / analytics services.</li>
      </ul>

      <h4 id="4">How do we store your data?</h4>
      <p>Performance IQ securely stores your data using GDPR compliant Amazon Web Services. More information can be found here: <a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/compliance/gdpr-center/">aws.amazon.com/compliance/gdpr-center/</a></p>

      <h4 id="5">Marketing</h4>
      <p>Performance IQ may send you information about services and features. If you have agreed to receive marketing, you may always opt out at a later date. You have the right at any time to stop Performance IQ from contacting you for marketing purposes.</p>

      <h4 id="6">What are your data protection rights?</h4>
      <p>Performance IQ would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
      <ul>
        <li>The right to access — You have the right to request Performance IQ for copies of your personal data.</li>
        <li>The right to rectification — You have the right to request that Performance IQ correct any information you believe is inaccurate. You also have the right to request Performance IQ to complete information you believe is incomplete.</li>
        <li>The right to erasure — You have the right to request that Performance IQ erase your personal data, under certain conditions.</li>
        <li>The right to restrict processing — You have the right to request that Performance IQ restrict the processing of your personal data, under certain conditions.</li>
        <li>The right to object to processing — You have the right to object to Performance IQ's processing of your personal data, under certain conditions.</li>
        <li>The right to data portability — You have the right to request that Performance IQ transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
      </ul>
      <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: <a href="mailto:Seattle-build@cbre.com">Seattle-build@cbre.com</a></p>

      <h4 id="7">What are cookies?</h4>
      <p>A cookie is a small text file that a website stores on your personal computer, telephone or any other device, with information about your navigation on that website. Cookies facilitate browsing and to make it more user-friendly. A cookie contains the name of the server it came from, the expiry of the cookie, and a value – usually a randomly generated unique number. Cookies do not give access to nor damage your computer.</p>

      <h4 id="8">How do we use cookies?</h4>
      <p>Performance IQ uses cookies in a range of ways to improve your experience on our website, including:</p>
      <ul>
        <li>Keeping you signed in.</li>
        <li>Understanding how you use our website.</li>
      </ul>

      <h4 id="9">What types of cookies do we use?</h4>
      <p>There are a number of different types of cookies, however, our website uses:</p>
      <ul>
        <li>Required cookies: These cookies are necessary to enable the basic features of this site to function, such as identifying you as a valid user.</li>
        <li>Functional cookies: These cookies allow us to analyze your use of the site to evaluate and improve our performance. They may also be used to provide a better customer experience on this site.</li>
      </ul>

      <h4 id="10">How to manage cookies</h4>
      <p>You can set your browser not to accept cookies at anytime in the browser settings. However, in a few cases, some of our website features may not function as a result.</p>

      <h4 id="11">Changes to our privacy policy</h4>
      <p>Performance IQ keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on November 12, 2019.</p>

      <h4 id="12">How to contact us</h4>
      <p>You are always free to contact us if you have questions or concerns regarding this Policy, have a question or problem related to your use of Performance IQ, or wish to exclude your personal information from our direct marketing purposes. Our standard business practice is to retain any communications from Performance IQ visitors to help us to serve you better.</p>
      <p>Email us at: <a href="mailto:Seattle-build@cbre.com">Seattle-build@cbre.com</a></p>
    </div>
  </div>
);

export default Privacy;
