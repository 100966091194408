import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useTable, useRowSelect, useSortBy, useBlockLayout } from 'react-table';
import { FixedSizeList as List, areEqual } from 'react-window';
import { Icon } from '@cbrebuild/blocks';
import './Table.scss';

const compareIgnoreCase = (val1, val2) => {
  // 0 > null, a > null should be true
  // to sort the number columns that are mixed with 0 and null nicely.
  if (val1 === null) {
    return -1;
  }

  // set all string to lower case for comparison
  if (typeof val1 === 'string') {
    if (val2 === null) {
      return 1;
    }
    return val1.toLowerCase() > val2.toLowerCase() ? 1 : -1;
  }

  if (val2 === null) {
    return 1;
  }
  return Number(val1) > Number(val2) ? 1 : -1;
};

const renderSortIcon = (col) => {
  if (col.isSorted) {
    return col.isSortedDesc ? <Icon iconName="arrow-down" /> : <Icon iconName="arrow-up" />;
  }
  return '';
};

// Use React memo to skip unnecessary rerendering of other rows
// when data props is received with a small change on a row
// i.e. override update
const Row = memo((props) => {
  const {
    data: { rows, prepareRow },
    index,
    style,
  } = props;

  const row = rows[index];
  prepareRow(row);
  const isRowIndexEven = !!(index % 2 === 0);
  const rowClass = `tr ${isRowIndexEven ? 'even' : 'odd'}`;
  return (
    <div
      {...row.getRowProps({ style })}
      className={rowClass}
    >
      {row.cells.map((cell) => {
        const title = cell.value || '';
        const cellEllipsis = (cell.column.ellipsis === false) ? '' : ' cell-ellipsis';
        const textAlign = (cell.column.rightAlign) ? ' text-align-right' : '';
        const cellClass = `td${cellEllipsis}${textAlign}`;
        return (
          <div {...cell.getCellProps({ title })} className={cellClass}>
            {cell.render('Cell')}
          </div>
        );
      })}
    </div>
  );
}, areEqual);

// react-table Table implementation, with option to select rows
const Table = ({
  columns,
  data,
  isLoading,
  tableHeight,
  isSortingAvailable,
  updateData,
  toggleQuickView,
  children,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      updateData,
      toggleQuickView,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          return compareIgnoreCase(
            row1.values[columnName],
            row2.values[columnName],
          );
        }
      },
    },
    useSortBy,
    useBlockLayout,
    useRowSelect,
  );

  const hasData = data.length > 0;

  const createItemData = useCallback((items, prepareRowFn) => ({
    rows: items,
    prepareRow: prepareRowFn,
  }), []);

  const itemData = createItemData(rows, prepareRow);

  return (
    <section className="table-section">
      <div className="table-scroll">
        {isLoading && <div className="card">Loading...</div>}
        {!isLoading && !hasData &&
          <div className="card">No pipeline data found with current filters/search.</div>}
        {!isLoading && hasData && (
          <div className="table" {...getTableProps()} role="table">
            <div>
              {headerGroups.map((headerGroup) => (
                <div className="tr" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((col) => {
                    const title = String(col.Header);
                    const headerClassName = isSortingAvailable ? 'sort-available fixed-line-header' : 'sort-disabled fixed-line-header';
                    return (
                      <div
                        {...col.getHeaderProps({ ...col.getSortByToggleProps(), title })}
                        className="th"
                      >
                        {/* Replacement of {col.render('Header')} */}
                        <div className="header-wrapper" colSpan="1" role="columnheader">
                          <span className={headerClassName}>{col.render('Header')}</span>
                          <span className="sorting-indicator">{renderSortIcon(col)}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()}>
              <List
                height={tableHeight}
                itemCount={rows.length}
                itemSize={48}
                itemData={itemData}
                width={totalColumnsWidth}
              >
                {Row}
              </List>
            </div>
          </div>
        )}
      </div>
      {children}
    </section>
  );
};

export default Table;

Row.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({
      getRowProps: PropTypes.func,
      cells: PropTypes.arrayOf(PropTypes.shape({}))
    })),
    prepareRow: PropTypes.func,
  }).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalLabel: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  rowIdAttr: PropTypes.string,
  tableHeight: PropTypes.number.isRequired,
  isSortingAvailable: PropTypes.bool,
  updateData: PropTypes.func,
  toggleQuickView: PropTypes.func,
  children: PropTypes.node,
};

Table.defaultProps = {
  totalLabel: 'Total',
  rowIdAttr: 'id',
  isSortingAvailable: true,
  updateData: () => {},
  toggleQuickView: () => {},
  children: null,
};
