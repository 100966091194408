/* global CONFIG */
import React from 'react';

import './PermissionDenied.scss';
import { Button } from '@cbrebuild/blocks';

// TODO: these buttons should probably be anchors
const PermissionDenied = () => {
  return (
    <section className="permission-denied-page">
      <h2>Sorry! You currently do not have access to Performance IQ.</h2>
      <p>If you believe this to be an error, please contact support.</p>
      <div className="button-container">
        <Button
          onClick={() => { window.location.replace(`${CONFIG.apiBaseURL}/oauth2/logout`); }}
        >Sign Out
        </Button>
        <Button
          onClick={() => { window.location.replace('mailto:molly.connelly@cbre.com'); }}
          variant="secondary"
        >Contact Support
        </Button>
      </div>
    </section>
  );
};

export default PermissionDenied;
