import React from 'react';
import PropTypes from 'prop-types';

import FilterTag from '../FilterTag';

import {
  getAppliedFiltersForTags,
  getEmptyParamsForFilter,
  getEmptyParamsForFilters,
} from '../../utils/filters';

const FilterTags = ({
  filters,
  params,
  applyParams
}) => {
  const handleClearFilter = (id) => {
    const filter = filters.find((f) => f.id === id);
    const updatedParams = getEmptyParamsForFilter(filter);
    applyParams(updatedParams);
  };

  const appliedFilters = getAppliedFiltersForTags(params, filters);

  if (!appliedFilters.length) return null;

  return (
    <div className="filter-tags-container">
      {appliedFilters.map(({ id, tagLabel, displayValue, minValue, maxValue }) => (
        <FilterTag
          key={id}
          label={tagLabel}
          value={displayValue}
          minValue={minValue}
          maxValue={maxValue}
          handleClearFilter={() => handleClearFilter(id)}
        />
      ))}
      {appliedFilters.length > 1 && (
        <span
          className="clear-all-button"
          onClick={() => applyParams(getEmptyParamsForFilters(filters))}
          role="button"
        >
          Clear All
        </span>
      )}
    </div>
  );
};

export default FilterTags;

FilterTags.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      tagLabel: PropTypes.string,
      displayValue: PropTypes.string,
      minValue: PropTypes.string,
      maxValue: PropTypes.string
    })
  ).isRequired,
  params: PropTypes.object.isRequired,
  applyParams: PropTypes.func.isRequired
};
