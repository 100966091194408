import React, { useContext } from 'react';
import sortBy from 'lodash/sortBy';
import PipelineContext from '../../context/PipelineContext';
import { numberFormat, dateFormat, currencyFormat, percentFormat } from '../../utils/formatters';
import './PipelineQuickView.scss';
import { parseDate, extractModifiedByName } from '../../utils/object-utils';

const NO_VALUE = '--';

const renderProducer = (comms) => {
  if (!comms.length) {
    return <div>{NO_VALUE}</div>;
  }

  const sortedByFullName = sortBy(comms, (com) => com.full_name);
  const sortedProducers = sortBy(sortedByFullName, (com) => !com.is_lead);
  return sortedProducers.map((producer, index) => {
    const {
      full_name: name,
      broker_gross_commission_percent: split,
      is_lead: lead,
      employee_id: employeeId,
    } = producer;

    const keyId = `${index}-${employeeId}`;
    return (
      <div key={keyId}>{name} ({numberFormat(split, 0)}%) {lead ? '- Lead' : ''}</div>
    );
  });
};

const renderMember = (collabs) => {
  if (!collabs.length) {
    return <div>{NO_VALUE}</div>;
  }

  const sortedByFirstName = sortBy(collabs, (coll) => coll.first_name);
  return sortedByFirstName.map((member) => {
    const {
      first_name: firstName,
      last_name: lastName,
    } = member;
    return (
      <div key={firstName}>{firstName} {lastName}</div>
    );
  });
};

const renderPerson = (person) => {
  if (person && typeof person === 'object') {
    const {
      first_name: firstName,
      last_name: lastName,
      id: ID,
    } = person;
    return (
      <div key={ID}> by {firstName} {lastName}</div>
    );
  }
  return null;
};

const renderOverrides = (overrides) => {
  if (!overrides) return <div>{NO_VALUE}</div>;

  // Custom display formatting for each field
  const keyMap = {
    conversion_potential_override: ['Conversion Potential', (val) => percentFormat(val)],
    installment_date_override: ['Installment Date', (val) => dateFormat(val)],
    total_commission_to_cbre_override: ['CBRE Gross Commission', (val) => currencyFormat(val)],
    is_on_pipeline_override: ['Selected in Performance IQ', () => {}]
  };

  return Object.entries(overrides).map(([key, fields]) => {
    const keyId = key + fields.modified; // unique div identifier

    if (keyMap[key]) {
      const displayKey = keyMap[key][0];
      const displayTime = fields.modified ? parseDate(fields.modified) : null;
      const displayName = fields.modified_by ? extractModifiedByName(fields.modified_by) : null;
      const addedOrRemoved = fields.value ? 'added' : 'removed';

      let displayVal;
      if (key === 'is_on_pipeline_override') {
        // don't count default "yes" as an override for is_on_pipeline_override
        if (!fields.modified) displayVal = NO_VALUE;
        else {
          displayVal = fields.value ? 'Yes' : 'No';
        }
      } else {
        displayVal = fields.value ? keyMap[key][1](fields.value) : NO_VALUE;
      }

      const overrideDetails = ((displayTime && displayName) &&
        <i>{addedOrRemoved} by {displayName} on {displayTime}</i>
      );

      return (
        <div key={keyId}>{displayKey}: {displayVal}<br />{overrideDetails}</div>
      );
    }
    return null;
  });
};

// Manager comments is a distinct section despite its similarity to renderOverrides, for ~reasons~
const renderManagerComments = (managerComments) => {
  if (!managerComments || !managerComments.modified) return <div>{NO_VALUE}</div>;

  const addedOrRemoved = managerComments.value ? 'added' : 'removed';
  const modifiedName = extractModifiedByName(managerComments.modified_by);
  const modifiedDate = parseDate(managerComments.modified);

  return (
    <div>
      {managerComments.value && <span>Comment: {managerComments.value}<br /></span>}
      <span>
        <i>{addedOrRemoved} by {modifiedName} on {modifiedDate}</i>
      </span>
    </div>
  );
};

const DealInfo = () => {
  const {
    quickViewData,
  } = useContext(PipelineContext);

  const {
    name,
    commission,
    members,
    overrides,
    pipeline_modified_by: updatedBy,
    last_updated_for_pipeline: updatedDate,
  } = quickViewData;

  return quickViewData && (
    <React.Fragment>
      <div className="deal-info-header">
        <div className="deal-name">{name}</div>
        <div>Updated On: {dateFormat(updatedDate) || NO_VALUE}{renderPerson(updatedBy)}</div>
      </div>
      <div className="deal-info-content">
        <section>
          <div className="section-header">Producer Team</div>
          {renderProducer(commission)}
        </section>
        <section>
          <div className="section-header">Deal Collaborators</div>
          {renderMember(members)}
        </section>
        <section>
          <div className="section-header">Override Info</div>
          {renderOverrides(overrides)}
        </section>
        <section>
          <div className="section-header">Manager Comments</div>
          {renderManagerComments(overrides ? overrides.manager_comments : null)}
        </section>
      </div>
    </React.Fragment>
  );
};

export default DealInfo;
