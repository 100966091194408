import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { currencyFormat, integerFormat, addressFormat } from '../../utils/formatters';

const LabeledItem = ({ label, item, format, itemClass }) => {
  let formatted = item;

  if (format && item) {
    if (format === 'utc') {
      formatted = moment.utc(item).format('ll');
    } else if (format === 'date') {
      formatted = moment(item).format('ll');
    } else if (format === 'integer') {
      formatted = integerFormat(item);
    } else if (format === 'currency') {
      formatted = currencyFormat(item);
    } else if (format === 'address') {
      formatted = addressFormat(item);
    }
  }

  return (
    <div className="labeled-item">
      <div className="label blx-ui-small-caps">{label}</div>
      <div className={itemClass}>
        {formatted || '--'}
      </div>
    </div>
  );
};

export default LabeledItem;

LabeledItem.propTypes = {
  label: PropTypes.string.isRequired,
  item: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
    PropTypes.shape({}), // allow address objects, must be used with prop format = 'address'
  ]),
  format: PropTypes.oneOf(['', 'utc', 'date', 'integer', 'currency', 'address']),
  itemClass: PropTypes.string,
};

LabeledItem.defaultProps = {
  item: '',
  format: '',
  itemClass: '',
};
