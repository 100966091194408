import React, { useContext } from 'react';
import { RadioGroup, RadioButton } from '@cbrebuild/blocks';
import PropTypes from 'prop-types';
import PipelineContext from '../../context/PipelineContext';

const { ActionModal } = require('@cbrebuild/blocks-react').Modals;

const urls = {
  ALL: 'pipeline_excel',
  FILTERS: 'pipeline_excel_with_filters'
};

const PipelineDownloadModal = ({ downloadFile, isOpen, close }) => {
  const [downloadType, setDownloadType] = React.useState(urls.FILTERS);
  const {
    totalCount,
    filteredSelectedCount,
  } = useContext(PipelineContext);

  return (
    <ActionModal
      isHidden={!isOpen}
      title="Performance IQ Pipeline Report"
      confirmText="Download"
      onConfirm={() => {
        downloadFile(downloadType);
        close();
      }}
      cancelText="Cancel"
      onCancel={close}
      onClose={close}
      variant="success"
    >
      <p className="modal-content">Download pipeline report for</p>
      <RadioGroup
        name="download"
        orientation="vertical"
        className="download-options"
        selectedValue={downloadType === urls.ALL ? 'all' : 'filtered'}
        onChange={(e) => {
          if (e.target.value === 'filtered') {
            setDownloadType(urls.FILTERS);
          } else {
            setDownloadType(urls.ALL);
          }
        }}
      >
        <RadioButton value="filtered">
          Deals with current filter/search results and selected only ({filteredSelectedCount} {filteredSelectedCount > 1 ? 'deals' : 'deal'})
        </RadioButton>
        <RadioButton value="all">
          All pipeline deals ({totalCount} {totalCount > 1 ? 'deals' : 'deal'})
        </RadioButton>
      </RadioGroup>
    </ActionModal>
  );
};

export default PipelineDownloadModal;

PipelineDownloadModal.propTypes = {
  downloadFile: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};
