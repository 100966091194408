import { uniq } from 'lodash';

const getLeadProducerName = (deal) => {
  // Search through commision objects to find lead producer
  // note: preserving logic from original code which assumes
  // that there will always be at least one producer in commision list
  for (let i = 0; i < deal.commission.length; i++) {
    if (deal.commission[i].is_lead) return deal.commission[i].full_name;
  }
  // If no producer recorded as lead, return first producer in array
  return deal.commission[0] ? deal.commission[0].full_name : null;
};

// Either show # rounded to 2 decimal points (or integer if .00) or Missing (if 0 or None commission)
const getBrokerNameAndCommissionPercent = (person) => (
  `${person.full_name} (${person.broker_gross_commission_percent ? `${person.broker_gross_commission_percent.toFixed(2).replace(/\.00$/, '')}%` : '*Missing'})`
);

const getMemberName = (person) => `${person.first_name} ${person.last_name}`;

const getProducerTeam = (deal) => {
  if (deal.commission.length === 0) return null;
  return deal.commission
    .map(getBrokerNameAndCommissionPercent)
    .join(', ');
};

const getCollaborators = (deal) => {
  if (!deal.members) return null;
  return deal.members
    .map(getMemberName)
    .join(', ');
};

const getManagingOffices = (deal) => {
  // compile list of brokers' managaing offices
  const offices = deal.commission.map((com) => `${com.functional_unit_name} 
  (${com.functional_unit}) / ${com.managing_office_name} (${com.managing_office})`);
  const uniqOffices = uniq(offices);
  return uniqOffices.join(', ');
};

export {
  getLeadProducerName,
  getProducerTeam,
  getManagingOffices,
  getCollaborators
};
