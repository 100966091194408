import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './BreadCrumbs.scss';

const BreadCrumbs = ({ crumbs }) => {
  return (
    <div className="breadcrumbs">
      {crumbs.map((crumb) => (
        <NavLink
          className="breadcrumb"
          exact
          key={crumb.name}
          to={crumb.route}
        >
          {crumb.name}
        </NavLink>
      ))}
    </div>
  );
};

BreadCrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
};

export default BreadCrumbs;
