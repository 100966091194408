/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '@cbrebuild/blocks';
import UserContext from '../../context/UserContext';
import Flyout from '../Flyout/index';
import './TopNav.scss';

const TopNav = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="topnav">
      <Flyout
        closeOnInsideClick
        trigger={(
          <React.Fragment>
            <Icon iconName="menu" />
            <div style={{ minHeight: '10px' }} />
          </React.Fragment>
        )}
        flyoutContent={(
          <div className="topnav-flyout flex-col">
            <NavLink to="/">Dashboard</NavLink>
            <NavLink to="/expiring-leases/">Lease Expirations</NavLink>
            <NavLink to="/pipeline/">Pipeline Report</NavLink>
            {user.can_access_generate_reports && <NavLink to="/generate-reports/">Generate Reports</NavLink>}
            <a className="intercom-trigger">Support</a>
          </div>
        )}
      />
    </div>
  );
};

export default TopNav;
