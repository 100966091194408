/* global
    CONFIG
*/

import axios from 'axios';

// Get CSRF token from the cookie
function getCsrfToken() {
  if (!document.cookie) {
    return null;
  }
  const csrfToken = document.cookie.split(';').find(c => c.trim().startsWith('csrftoken='));
  if (!csrfToken) {
    return null;
  }
  return decodeURIComponent(csrfToken.split('=')[1]);
}

const API = axios.create({
  baseURL: CONFIG.apiBaseURL,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  headers: {
    'X-CSRFToken': getCsrfToken(),
  },
  withCredentials: true,
});

API.CancelToken = axios.CancelToken;
API.isCancel = axios.isCancel;

export default API;
