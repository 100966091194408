/* eslint-disable camelcase */
import React, { useState, useContext } from 'react';

import { Button } from '@cbrebuild/blocks';
import UserContext from '../../context/UserContext';
import Autocomplete from '../Autocomplete';
import Tag from '../Tag';
import API from '../../services/API';
import './ManagingOfficeAccess.scss';

const { BaseModal } = require('@cbrebuild/blocks-react').Modals;

const ManagingOfficeAccess = () => {
  const { user } = useContext(UserContext);
  const { fmc } = user;

  const [requestedLocations, setRequestedLocations] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('requestAccess');

  // Modal Controls
  const closeModal = () => {
    setModalVisible(false);
    setModalContent('requestAccess');
    setRequestedLocations([]);
  };

  const handleSubmit = async () => {
    const payload = {
      locations: requestedLocations,
    };
    await API.post('/api/locations/request/', payload);
    setModalContent('requestSubmitted');
  };

  // Autocomplete Controls
  const handleSelect = (location) => {
    const updatedLocations = [...requestedLocations, location];
    setRequestedLocations(updatedLocations);
  };

  const handleSearch = async (text) => {
    const params = {
      query: text,
      size: 25,
    };
    const response = await API.get('/api/locations/', { params });
    return response.data.locations.filter((location) => (!(
      // remove offices which they already have access to
      fmc.map((item) => item.fumo_code).includes(location.fumo_code) ||
      // as well as offices they have already requested access to
      requestedLocations.map((item) => item.fumo_code).includes(location.fumo_code)
    )));
  };

  const formatSuggestion = (suggestion) => (
    <React.Fragment>
      <div>{`${suggestion.functional_unit_name}`}</div>
      <div className="subtext">{`${suggestion.managing_office_name} (${suggestion.managing_office_code})`}</div>
    </React.Fragment>
  );

  // Tag Controls
  const handleRemoveTag = (fumo_code) => {
    const updatedLocations = requestedLocations.filter((location) => location.fumo_code !== fumo_code);
    setRequestedLocations(updatedLocations);
  };

  // Modals
  const modalContent1 = (
    <React.Fragment>
      <div className="blx-modal-title">
        <h4>Request Access to Managing Offices</h4>
      </div>
      <p>Request access to additional managing offices below.</p>
      <Autocomplete
        search={handleSearch}
        onSelect={handleSelect}
        formatSuggestion={formatSuggestion}
        suggestionKey="fumo_code"
        placeholder="Search managing offices"
      />
      <div className="tag-container">
        {requestedLocations.map((location) => (
          <Tag
            key={location.fumo_code}
            label={location.functional_unit_name}
            smallLabel={location.managing_office_name}
            fumo_code={location.fumo_code}
            onClose={(tag) => handleRemoveTag(tag)}
          />
        ))}
      </div>
      <div className="blx-v-button-group">
        <Button
          onClick={closeModal}
          variant="secondary"
        >Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </React.Fragment>
  );

  const modalContent2 = (
    <React.Fragment>
      <div className="blx-modal-title"><h4>Your Request Has Been Submitted.</h4></div>
      <p>
        Your request to access the following managing offices will be reviewed
        by the CBRE Sales Operations Team. You will be alerted upon approval.
      </p>
      {requestedLocations.map((location) => (
        <b key={location.fumo_code}>
          {`${location.functional_unit_name} - ${location.managing_office_name} (${location.fumo_code})`}
        </b>
      ))}
      <p>
        Forgot something? <u onClick={() => setModalContent('requestAccess')}>Modify my request.</u>
      </p>
      <div className="blx-v-button-group">
        <Button onClick={closeModal}>Done</Button>
      </div>
    </React.Fragment>
  );

  return (
    <section className="managing-office-access">
      <h3>Your Assigned Managing Offices</h3>
      {!!fmc.length && (
        <p className="mb1">
          You currently have access to {fmc.length} managing offices. To add additional managing
          offices, select Request Access below, or contact <u>CBRE Sales Operations</u>
        </p>
      )}
      <div className="office-list scroll-box">
        {
          fmc.map(({ functional_unit_name, fumo_code, managing_office_code, managing_office_name }) => (
            <div key={fumo_code}>
              {`${functional_unit_name} - ${managing_office_name} (${managing_office_code})`}
            </div>
          ))
        }
        {!fmc.length && (
          <p>
            You currently have access to 0 managing offices. To add managing offices, select
            Request Access below, or contact <u>CBRE Sales Operations</u>
          </p>
        )}
      </div>
      <h3>Change Your Assigned Managing Offices</h3>
      <span
        className="text-button"
        onClick={() => setModalVisible(true)}
        role="button"
      >
        + Request Access
      </span>
      <section className="request-access-modal">
        <BaseModal
          key="baseModal"
          isHidden={!modalVisible}
          onClose={closeModal}
        >
          {modalContent === 'requestAccess' && modalContent1}
          {modalContent === 'requestSubmitted' && (
            <React.Fragment>
              {modalContent2 || 'Oops, something has gone wrong. Try again later.'}
            </React.Fragment>
          )}
        </BaseModal>
      </section>
    </section>
  );
};

export default ManagingOfficeAccess;
