import React, { useContext } from 'react';
import { Button } from '@cbrebuild/blocks';
import UserContext from '../../context/UserContext';
import API from '../../services/API';

import './HijackBanner.scss';

const HijackBanner = () => {
  const { user } = useContext(UserContext);

  const endHijackSession = async () => {
    const response = await API.post('/hijack/release-hijack/');
    const redirectURL = response.request.responseURL;
    window.location.replace(redirectURL);
  };

  return (
    <div className="hijack-banner">
      You are currently working on behalf of {user.first_name} {user.last_name}.
      <Button
        onClick={endHijackSession}
        variant="secondary"
      >End Session
      </Button>
    </div>
  );
};

export default HijackBanner;
