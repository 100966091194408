/* eslint-disable max-len */
import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import API from '../services/API';
import { DEFAULT_PIPELINE_TABLE_CONFIG, PIPELINE_COLUMN_DATA } from '../constants/table';
import pipelineService from '../services/pipeline-service';
import BreadCrumbs from '../components/BreadCrumbs';
import Table from '../components/Table';
import PipelineTotalBoxes from '../components/PipelineTotalBoxes';
import './Pipeline.scss';
import UserContext from '../context/UserContext';
import PipelineControlBar from '../components/PipelineControlBar';
import userService from '../services/user-service';
import PipelineContext from '../context/PipelineContext';
import QuickView from '../components/Table/QuickView';
import PipelineQuickView from '../components/PipelineQuickView';
import { fillOverrides } from '../utils/object-utils';

const DEAL_ID_ATTR = 'deal_iq_id';

const breadCrumbs = [
  {
    route: '/',
    name: 'Dashboard',
  },
  {
    route: '/pipeline/',
    name: 'Pipeline Report'
  }
];

// Input values should be type corrected before it is being sent to BE
// Also, number fields should not be string for aggregates calculations.
// is_on_pipline_override: boolean
// total_commission_to_cbre_override: float number
// conversion_potential_override: float number
// installment_date_override: string 'YYYY-MM-DD'
const correctTypeByKey = (key, value) => {
  let correctedValue = value;

  switch (key) {
    case 'conversion_potential_override':
    case 'total_commission_to_cbre_override': {
      if (value === '') {
        correctedValue = null;
      } else {
        correctedValue = parseFloat(value);
      }
      break;
    }
    default:
      // do nothing
  }
  return correctedValue;
};

const convertOverrideStringToNumber = (overrideStr) => {
  return Number.isNaN(parseFloat(overrideStr)) ? null : parseFloat(overrideStr);
};

const DEFAULT_PARAMS = {
  page: '1',
  page_size: '100',
};

const shouldNotRerender = (prev, next) => {
  return isEqual(prev.params, next.params) && isEqual(prev.isPipelineLoading, next.isPipelineLoading);
};
const MemoizedControlBar = React.memo((props) => <PipelineControlBar {...props} />, shouldNotRerender);

const Pipeline = () => {
  const [tableConfig, setTableConfig] = useState(DEFAULT_PIPELINE_TABLE_CONFIG);
  const [pipelineDeals, setPipelineDeals] = useState([]);
  const [aggregates, setAggregates] = useState({});
  const [params, setParams] = useState(DEFAULT_PARAMS); // page_size 100 takes aboutn 300 -1000 ms.
  const [isLoading, setIsLoading] = useState(true);
  const [isAggrLoading, setIsAggrLoading] = useState(true);
  const [isSortingAvailable, setIsSortingAvailable] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [tableHeight, setTableHeight] = useState(500);
  const [quickViewData, setQuickViewData] = useState(null);

  const [totalCount, setTotalCount] = useState(0);
  const [filteredTotalCount, setFilteredTotalCount] = useState(0);
  const [filteredSelectedCount, setFilteredSelectedCount] = useState(0);
  const isFirst = useRef(true);

  // set download total even when the first params are not default
  const getPipelineTotalCount = useCallback(async (token) => {
    const res = await pipelineService.fetchPipeline({ page: 1, page_size: 1 }, token);
    setTotalCount(res.count);
  }, []);

  useEffect(() => {
    const { token, cancel } = API.CancelToken.source();
    if (isFirst.current && !isEqual(params, DEFAULT_PARAMS)) {
      getPipelineTotalCount(token);
    }

    return (() => {
      cancel('getting total count is canceled');
    });
  }, [getPipelineTotalCount, params]);

  // update user table config in case there is a change in default setting from the codebase
  useEffect(() => {
    const updateUserPipelineTableConfig = async () => {
      // if user config already exists, check if there is removed or updated column from default
      // and then update it.
      const hasUserConfig = user.pipeline_table_configuration && user.pipeline_table_configuration.length > 0;
      if (hasUserConfig) {
        const userConfig = user.pipeline_table_configuration;
        const permenantConfig = DEFAULT_PIPELINE_TABLE_CONFIG.filter((col) => {
          return col.isPermanent;
        });
        const defaultConfig = DEFAULT_PIPELINE_TABLE_CONFIG.filter((col) => !col.isPermanent);
        const userRemainingConfig = userConfig.filter((col) => {
          const index = defaultConfig.findIndex((dCol) => {
            return dCol.id === col.id;
          });
          return index > -1;
        });

        const addedDefaultConfig = defaultConfig.filter((dCol) => {
          const index = userConfig.findIndex((col) => {
            return col.id === dCol.id;
          });
          return index < 0;
        });

        // if any column in config has removed or added, update the user config
        if (userRemainingConfig.length < userConfig.length || addedDefaultConfig.length > 0) {
          const newConfig = [...userRemainingConfig, ...addedDefaultConfig];
          const payload = { pipeline_table_configuration: newConfig };
          setTableConfig([
            ...permenantConfig,
            ...newConfig,
          ]);
          const response = await userService.updateUser(payload);
          setUser(response.data);
        }
      }
    };

    updateUserPipelineTableConfig();
  }, [setUser, user.pipeline_table_configuration]);

  const resizeTable = useCallback(() => {
    // approximate number of app header (76) + top and bottom padding (40) + table header (50)
    // + breadcrumbs (33) + page title + (61) + total boxes (105) + extra scroll padding (20) = 380
    let otherHeight = 380;

    if (user.is_hijacked) {
      otherHeight += 30;
    }

    // add flexible control bar height
    if (document.getElementsByClassName('control-bar').length) {
      const controlHeight = document.getElementsByClassName('control-bar')[0].clientHeight;
      otherHeight += controlHeight;
    }

    // add flexible filter height
    if (document.getElementsByClassName('filter-tags-container').length) {
      const filterHeight = document.getElementsByClassName('filter-tags-container')[0].clientHeight;
      otherHeight += filterHeight; // filter tags container height
    }
    setTableHeight(window.innerHeight - otherHeight);
  }, [user.is_hijacked]);

  // fetch all pipelines
  const startBackgroundFetching = (queryParams, count, cancelToken) => {
    const size = 1000; // page_size 1000 takes about 1500 - 3000 ms.
    const maxLoop = Math.ceil(count / parseInt(size));
    let page = 1;
    let newQueryParams = {};
    const batch = [];
    let batchResults = [];

    for (page; page <= maxLoop; page++) {
      newQueryParams = {
        ...queryParams,
        page,
        page_size: size,
      };
      batch.push(pipelineService.fetchPipeline(newQueryParams, cancelToken));
    }

    Promise.all(batch)
      .then((res) => {
        for (let i = 0; i < res.length; i++) {
          if (res[i]) {
            batchResults = [...batchResults, ...res[i].results];
          }
        }
        // set state
        setPipelineDeals(batchResults);
        setIsSortingAvailable(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPipelines = useCallback(async (queryParams, cancelToken) => {
    setIsSortingAvailable(false);
    setIsLoading(true);

    // set pipelines from BE API
    const res = await pipelineService.fetchPipeline(queryParams, cancelToken);
    if (res) {
      // initial pipline table data to start display
      // before it gets the entire data
      setPipelineDeals(res.results);
      setIsLoading(false);
      resizeTable();

      // get rest of the data in the background
      if (res.next) {
        startBackgroundFetching(queryParams, res.count, cancelToken);
      } else {
        setIsSortingAvailable(true);
      }

      if (isFirst.current && isEqual(queryParams, DEFAULT_PARAMS)) {
        setTotalCount(res.count);
      }
    }
  }, [resizeTable]);

  const fetchAggregates = useCallback(async (queryParams, cancelToken) => {
    setIsAggrLoading(true);
    const results = await pipelineService.fetchPipelineAggregates(queryParams, cancelToken);
    if (results) {
      setAggregates(results);
      setIsAggrLoading(false);
      setFilteredSelectedCount(results.number_of_selected_deals);
      setFilteredTotalCount(results.total_count);
    }
  }, []);

  // handle pipeline/aggregate fetches and response to all param changesq
  useEffect(() => {
    // refresh the token and start fetching
    const cancelTokenSource = API.CancelToken.source();
    fetchPipelines(params, cancelTokenSource.token);
    fetchAggregates(params, cancelTokenSource.token);
    // add resize event listener
    window.addEventListener('resize', resizeTable);
    return () => {
      // cancel previous fetches that uses the stale token
      cancelTokenSource.cancel('fetch canceled by new request');
      // remove resize event listener
      window.removeEventListener('resize', resizeTable);
    };
  }, [params, fetchPipelines, fetchAggregates, resizeTable]);

  const applyParams = useCallback((newParams) => {
    // get rid of null values to make proper POST request
    const cleanedParams = {};

    // make a copy of params
    for (const [key, value] of Object.entries(params)) {
      cleanedParams[key] = value;
    }

    for (const [key, newValue] of Object.entries(newParams)) {
      if (newValue == null) {
        // if previously applied filter now null, remove it
        if (params[key]) {
          delete cleanedParams[key];
        }
      } else {
        // if new filter value non-null, add or update
        cleanedParams[key] = newValue;
      }
    }
    setParams(cleanedParams);
  }, [params]);

  const calculateAggregates = useCallback((value, key, deal) => {
    let countOperand = 0;
    let totalGrossCommissionSelectedDiff = 0;
    let totalGrossCommissionAdjustedDiff = 0;
    let totalGrossCommissionFromDealIqDiff = 0;

    const isUserFiltered = !!params.fumo_employee_filters;
    const filteredProducerIds = isUserFiltered ? params.fumo_employee_filters.split(',').map((param) => {
      return param.split('-')[2];
    }) : [];

    // Convert to numbers (FE deal object fields come in as strings)
    const commissionOverrideOnDeal = convertOverrideStringToNumber(deal.overrides.total_commission_to_cbre_override.value);
    const conversionOverrideOnDeal = convertOverrideStringToNumber(deal.overrides.conversion_potential_override.value);

    // TGC: no-override version (for selected calculation) and override version (for adjusted calculation)
    const totalGrossCommissionNoOverride = (deal.is_mta ? deal.total_gross_commission : deal.total_gross_commission_display_value) || 0;
    const totalGrossCommissionForAdjustedCalc = (commissionOverrideOnDeal !== null) ? commissionOverrideOnDeal : (totalGrossCommissionNoOverride || 0);

    // conversion potential
    const conversionPotentialForAdjustedCalc = (conversionOverrideOnDeal !== null) ? conversionOverrideOnDeal : (deal.conversion_potential || 0);

    if (key === 'is_on_pipeline_override') {
      // logic when user select/unselects a deal
      // count
      countOperand = value ? 1 : -1;

      // total gross commission from DealIQ
      totalGrossCommissionFromDealIqDiff = countOperand * totalGrossCommissionNoOverride;

      // total gross commission selected and adjusted
      if (isUserFiltered) {
        deal.commission.forEach((com) => {
          const brokerCom = com.broker_gross_commission_percent || 0;

          if (filteredProducerIds.includes(com.employee_id)) {
            totalGrossCommissionSelectedDiff +=
              (brokerCom / 100)
              * totalGrossCommissionNoOverride;
            totalGrossCommissionAdjustedDiff +=
              (brokerCom / 100)
              * (totalGrossCommissionForAdjustedCalc)
              * (conversionPotentialForAdjustedCalc / 100);
          }
        });
        totalGrossCommissionSelectedDiff *= countOperand;
        totalGrossCommissionAdjustedDiff *= countOperand;
      }

      const newAggregates = {
        total_count: aggregates.total_count,
        number_of_selected_deals: aggregates.number_of_selected_deals + countOperand,
        total_gross_commission_selected: aggregates.total_gross_commission_selected + totalGrossCommissionSelectedDiff,
        total_gross_commission_adjusted: aggregates.total_gross_commission_adjusted + totalGrossCommissionAdjustedDiff,
        total_gross_commission_from_deal_iq: aggregates.total_gross_commission_from_deal_iq + totalGrossCommissionFromDealIqDiff,
      };
      setFilteredSelectedCount((old) => old + countOperand);
      setAggregates(newAggregates);
    } else if (isUserFiltered && deal.overrides.is_on_pipeline_override.value !== false) {
      // logic if user updates commission or probability override of a deal that affects total_gross_commission_adjusted
      // replace old adjusted gross commission total with new adjusted gross commission total

      // old adjusted for the deal
      let oldAdjusted = 0;
      deal.commission.forEach((com) => {
        if (filteredProducerIds.includes(com.employee_id)) {
          oldAdjusted += ((com.broker_gross_commission_percent || 0) / 100)
            * totalGrossCommissionForAdjustedCalc
            * (conversionPotentialForAdjustedCalc / 100);
        }
      });

      // new adjusted
      let newTotalGrossCommissionForAdjustedCalc = totalGrossCommissionForAdjustedCalc;
      let newConversionPotentialForAdjustedCalc = conversionPotentialForAdjustedCalc;

      if (key === 'total_commission_to_cbre_override') {
        newTotalGrossCommissionForAdjustedCalc = (value === null) ? totalGrossCommissionNoOverride : (value || 0);
      }

      if (key === 'conversion_potential_override') {
        newConversionPotentialForAdjustedCalc = (value === null) ? deal.conversion_potential : (value || 0);
      }

      let newAdjusted = 0;
      deal.commission.forEach((com) => {
        if (filteredProducerIds.includes(com.employee_id)) {
          newAdjusted += ((com.broker_gross_commission_percent || 0) / 100)
            * newTotalGrossCommissionForAdjustedCalc
            * (newConversionPotentialForAdjustedCalc / 100);
        }
      });
      setAggregates({
        ...aggregates,
        total_gross_commission_adjusted: aggregates.total_gross_commission_adjusted - oldAdjusted + newAdjusted,
      });
    }
  }, [aggregates, params.fumo_employee_filters]);

  // Update pipelineDeals state with fresh overrides
  const updatePipelineDealState = (resOverrides, deal, rowIndex) => {
    const newOverrides = fillOverrides(resOverrides);

    setPipelineDeals((old) => old.map((row, index) => {
      /*
        Select by column id, not deal_iq_id:
        For linked deals w/ multiple transactions, deal_iq_id isn't unique
      */
      if (index === rowIndex) {
        return {
          ...row,
          overrides: newOverrides,
        };
      }
      return row;
    }));
  };

  // Patch the database and update state with new override info
  const savePipelineDealUpdate = useCallback((value, key, deal, rowIndex) => {
    const payload = {
      [DEAL_ID_ATTR]: deal[DEAL_ID_ATTR],
      [key]: value,
    };

    return pipelineService.updateOrCreate(payload)
      .then((res) => updatePipelineDealState(res.data.overrides, deal, rowIndex));
  }, []);

  const updateData = useCallback((value, key, rowIndex) => {
    /*
      Use pipelineDeals data to compare input value with current deals data
      Don't use row data from tables component as it can hold data updates
      to disable rerendering on sorting + data change
    */
    const deal = pipelineDeals[rowIndex];
    const newVal = correctTypeByKey(key, value);
    const oldVal = (deal.overrides && deal.overrides[key]) ? deal.overrides[key].value : '';

    if (newVal !== oldVal) {
      savePipelineDealUpdate(newVal, key, deal, rowIndex);
      calculateAggregates(newVal, key, deal);
    }
  }, [pipelineDeals, updatePipelineDealState, savePipelineDealUpdate, calculateAggregates]);

  const [showQuickView, setShowQuickView] = useState(false);
  const toggleQuickView = (data) => {
    if (quickViewData) {
      setShowQuickView(false);
      setTimeout(() => {
        setQuickViewData(null);
      }, 1000);
    } else {
      setQuickViewData(data);
      setShowQuickView(true);
    }
  };

  const columns = useMemo(() => {
    return tableConfig
      .filter((col) => !col.hidden)
      .map((col) => PIPELINE_COLUMN_DATA[col.id]);
  }, [tableConfig]);

  return (
    <PipelineContext.Provider value={{ totalCount, filteredSelectedCount, filteredTotalCount, quickViewData }}>
      <section className="pipeline">
        <BreadCrumbs crumbs={breadCrumbs} />
        <h2>Pipeline Report</h2>
        <PipelineTotalBoxes
          aggregates={aggregates}
          isLoading={isAggrLoading}
        />
        <MemoizedControlBar
          params={params}
          applyParams={applyParams}
          tableConfig={tableConfig}
          isPipelineLoading={isLoading}
        />
        <Table
          tableHeight={tableHeight}
          columns={columns}
          data={pipelineDeals}
          isLoading={isLoading}
          allowSelectAll={false}
          rowIdAttr={DEAL_ID_ATTR}
          isSortingAvailable={isSortingAvailable}
          updateData={updateData}
          toggleQuickView={toggleQuickView}
        >
          {quickViewData && (
            <QuickView toggleQuickView={toggleQuickView} show={showQuickView}>
              <PipelineQuickView />
            </QuickView>
          )}
        </Table>
      </section>
    </PipelineContext.Provider>
  );
};

export default Pipeline;
