import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import './Autocomplete.scss';
import { Icon, IconButton } from '@cbrebuild/blocks';

const Autocomplete = ({
  search,
  placeholder,
  suggestionKey,
  onSelect,
  formatSuggestion,
}) => {
  const [inputText, setInputText] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = async (text) => {
    if (text) {
      setSuggestions(await search(text));
    } else {
      setSuggestions([]);
    }
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 250), [setSuggestions, search]);

  const clear = () => {
    setInputText('');
    setSuggestions([]);
  };

  const onInputChange = (event) => {
    const { value } = event.target;
    setInputText(value);
    debouncedSearch(value);
  };

  return (
    <div className="autocomplete-container">
      <Icon iconName="magnifier" />
      <input
        type="text"
        value={inputText}
        onChange={onInputChange}
        placeholder={placeholder}
      />
      {inputText && (
        <IconButton
          iconName="close-circle"
          onClick={clear}
          variant="basic"
        />
      )}
      <div className="suggestions">
        {suggestions.map((sug) => (
          <div
            className="suggestion"
            key={sug[suggestionKey]}
            onClick={() => {
              onSelect(sug);
              clear();
            }}
            role="button"
          >
            {formatSuggestion(sug)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Autocomplete;

Autocomplete.propTypes = {
  search: PropTypes.func.isRequired,
  formatSuggestion: PropTypes.func.isRequired,
  suggestionKey: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

Autocomplete.defaultProps = {
  placeholder: '',
  suggestionKey: '',
};
