const fullstoryIdentify = (user) => {
  if (
    !window.FS
    || !user.can_access_perf_iq
    || !user.has_accepted_terms_of_service
  ) {
    return;
  }

  const fullstoryIdentityData = {
    displayName: `${user.first_name} ${user.last_name}`,
    email: user.email,
  };

  // Docs: https://app.fullstory.com/ui/B0M3C/settings/general
  window.FS.identify(String(user.id), fullstoryIdentityData);
};

export default fullstoryIdentify;
