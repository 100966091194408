import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';
import Datepicker from '../Datepicker';
import { currencyFormat, dateFormat } from '../../utils/formatters';

import './InlineEdit.scss';

const {
  Input: { TextField },
  Dropdowns: { Dropdown },
} = require('@cbrebuild/blocks-react');

// convenience wrappers around input components
const NumberTextField = (props) => (
  <TextField type="number" {...props} />);

const PercentTextField = (props) => (
  <TextField type="number" suffix="%" {...props} />);

const CurrencyTextField = (props) => (
  <TextField type="number" prefix="$" {...props} />);

const DatePickerField = (props) => (
  <Datepicker {...props} />);

const INPUT_TYPES = {
  TEXT: 'text',
  PERCENT: 'percent',
  CURRENCY: 'currency',
  NUMBER: 'number',
  DATE: 'date',
  DROPDOWN: 'dropdown',
};

// inputComponent
const inputTypeToComponentMap = {
  text: TextField,
  date: DatePickerField,
  percent: PercentTextField,
  currency: CurrencyTextField,
  number: NumberTextField,
  dropdown: Dropdown,
};

const EMPTY_CELL_PLACEHOLDER = '--';

// InlineEdit Component
const InlineEdit = ({ inputType, value: initialValue, update, id }) => {
  // initial input value should not be null and 0 is a valid input
  const [value, setValue] = useState((initialValue !== null) ? initialValue : '');
  const [displayValue, setDisplayValue] = useState(EMPTY_CELL_PLACEHOLDER);
  const [isDateOverdue, setDateOverDue] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    update(e.target.value);
  };

  const displayDateOverride = (date) => {
    const currentDate = new Date().getTime();
    const installmentDate = new Date(date).getTime();
    if (installmentDate < currentDate) {
      setDateOverDue(true);
    } else {
      setDateOverDue(false);
    }
  };

  const handleDayChange = (day) => {
    setValue(day);
    displayDateOverride(day);
    update(day);
  };

  // whenever value changes, set new foramtted value for display
  useEffect(() => {
    let formattedValue = value;
    if (value === '' || value === null) {
      formattedValue = EMPTY_CELL_PLACEHOLDER;
    } else {
      switch (inputType) {
        case INPUT_TYPES.PERCENT: {
          formattedValue = `${value} %`;
          break;
        }
        case INPUT_TYPES.CURRENCY: {
          formattedValue = `${currencyFormat(value)}`;
          break;
        }
        case INPUT_TYPES.DATE: {
          displayDateOverride(value);
          formattedValue = `${dateFormat(value)}`;
          break;
        }
        default:
        // do nothing
      }
    }
    setDisplayValue(formattedValue);
  }, [inputType, value]);

  const getInputComponent = () => {
    const InputComponent = inputTypeToComponentMap[inputType];

    // set exceptional props for datepicker
    const props = (inputType === INPUT_TYPES.DATE) ?
      { value, onChange: handleDayChange } :
      { value, onChange: handleChange, onBlur: handleBlur };

    return (
      <InputComponent {...props} />
    );
  };

  return (
    <div className="inline-edit-container">
      <div className="inline-edit">
        <span className="inline-edit-input">
          {getInputComponent()}
        </span>
        <span className="inline-edit-value">
          {displayValue}
        </span>
      </div>
      {isDateOverdue && id === 'installment_date_override' && (
        <div className="past-due">
          <Icon iconName="past-due-date" />
          <span className="past-due-tooltip">Installment Date Override Is Past Due</span>
        </div>
      )}
    </div>
  );
};

InlineEdit.propTypes = {
  inputType: PropTypes.oneOf(
    Object.values(INPUT_TYPES)
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  update: PropTypes.func.isRequired,
  id: PropTypes.string
};

InlineEdit.defaultProps = {
  inputType: INPUT_TYPES.TEXT,
  value: '',
  id: ''
};

export { InlineEdit, INPUT_TYPES };
