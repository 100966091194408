import API from './API';

const url = '/api/me/';

const fetchUser = () => {
  return API.get(url)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

const updateUser = (payload) => {
  return API.patch('/api/update_user/', payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

export default {
  fetchUser,
  updateUser,
};
