/* global CONFIG */

let previousLocation = null;

const getEmployeeId = () => window.localStorage.getItem('employee_id');

const setEventUser = (user) => {
  if (!window.gtag || !user) {
    return;
  }
  window.gtag('set', { user_id: user });
};

const storeEmployeeId = (id) => {
  window.localStorage.setItem('employee_id', id);
  setEventUser(id);
};

const trackPageView = (data) => {
  if (!window.gtag || data.pathname === previousLocation) {
    return;
  }

  window.gtag('config', CONFIG.googleAnalyticsId, {
    page_referrer: window.location.origin + (previousLocation || '')
  });
  previousLocation = data.pathname;
};

const trackEvent = (data) => {
  // track events with Google Analytics using their "gtag" function
  // window.gtag should only exist in staging and prod
  if (!window.gtag) {
    return;
  }

  const gaArgs = { page_referrer: null };

  // format event for Google Analytics
  // https://developers.google.com/analytics/devguides/collection/gtagjs/events

  if (data.eventCategory) {
    gaArgs.event_category = data.eventCategory;
  }

  if (data.eventLabel) {
    gaArgs.event_label = data.eventLabel;
  }

  if (data.value) {
    gaArgs.value = data.value;
  }

  if (data.customData) {
    Object.assign(gaArgs, data.customData);
  }

  // track events
  window.gtag('event', data.eventAction, gaArgs);
};

// set user_id for GA events
setEventUser(getEmployeeId());

export {
  trackEvent,
  trackPageView,
  storeEmployeeId,
};
