/* global CONFIG */
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { Button } from '@cbrebuild/blocks';
import UserContext from '../context/UserContext';

const Login = () => {
  const { user } = useContext(UserContext);

  return (
    <React.Fragment>
      {user && user.email && <Redirect to="/" push />}
      <h2>Welcome.</h2>
      <Button
        onClick={() => {
          window.location.replace(`${CONFIG.apiBaseURL}/oauth2/login`);
        }}
      >Sign In
      </Button>
    </React.Fragment>
  );
};

export default Login;
