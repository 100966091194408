/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Checkbox } from '@cbrebuild/blocks';
import { PIPELINE_COLUMN_DATA } from '../../constants/table';
import UserContext from '../../context/UserContext';
import './EditColumnsButton.scss';
import userService from '../../services/user-service';

const { ActionModal } = require('@cbrebuild/blocks-react').Modals;
const { SortableList } = require('@cbrebuild/blocks-react');

const getOptionsFromColumns = (tableConfig) => {
  const numberOfHiddenCols = tableConfig.filter((col) => col.hidden).length;
  const opts = [{
    value: tableConfig.map((col) => col.id),
    text: 'Select All',
    checked: numberOfHiddenCols === 0,
    indeterminate: numberOfHiddenCols > 0 && numberOfHiddenCols < tableConfig.length - 1
  }];

  tableConfig.forEach((col) => {
    if (!PIPELINE_COLUMN_DATA[col.id].isPermanent) {
      const opt = {
        value: col.id,
        text: PIPELINE_COLUMN_DATA[col.id].Header,
        checked: !col.hidden
      };
      opts.push(opt);
    }
  });
  return opts;
};

const getTableConfigFromOptions = (options, oldTableConfig) => {
  const permCol = oldTableConfig.filter((col) => PIPELINE_COLUMN_DATA[col.id].isPermanent);
  const newTableConfig = [{ id: permCol[0].id }];
  options.forEach((opt) => {
    if (opt.text !== 'Select All') {
      const colConfig = {
        id: opt.value,
        hidden: !opt.checked
      };
      newTableConfig.push(colConfig);
    }
  });
  return newTableConfig;
};

const EditColumnsButton = ({ tableConfig }) => {
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [options, setOptions] = React.useState(getOptionsFromColumns(tableConfig));
  const { setUser } = React.useContext(UserContext);

  const updateUser = async () => {
    const newTableConfig = getTableConfigFromOptions(options, tableConfig);
    const payload = { pipeline_table_configuration: newTableConfig };
    const response = await userService.updateUser(payload);
    setUser(response.data);
  };

  const handleChange = (checked, option) => {
    let updatedOptions;

    if (option.text === 'Select All') {
      updatedOptions = options.map((opt) => {
        return {
          value: opt.value,
          text: opt.text,
          checked
        };
      });
    } else {
      updatedOptions = options.map((opt) => {
        if (opt.value === option.value) {
          return {
            value: opt.value,
            text: opt.text,
            checked
          };
        }
        return opt;
      });

      const numberOfUpdatedHiddenCols = updatedOptions.filter((col) => !col.checked && col.text !== 'Select All').length;
      updatedOptions = updatedOptions.map((opt) => {
        if (opt.text === 'Select All') {
          return {
            value: opt.value,
            text: opt.text,
            checked: numberOfUpdatedHiddenCols === 0,
            indeterminate: numberOfUpdatedHiddenCols > 0 && numberOfUpdatedHiddenCols < tableConfig.length - 1
          };
        }
        return opt;
      });
    }

    setOptions(updatedOptions);
  };

  /*
  the 2 indeces have +1s on them to account for Select All being the 0th index
  in the options list, but not being in the sortable list
  */
  const handleOrderChange = (oldIndex, newIndex) => {
    const updatedOpts = options;
    const reorderedOpt = updatedOpts.splice(oldIndex + 1, 1)[0];
    updatedOpts.splice(newIndex + 1, 0, reorderedOpt);
    setOptions(updatedOpts);
  };

  const sortableOpts = options.filter((opt) => opt.text !== 'Select All').map((option) => {
    return (
      <Checkbox
        key={option.value}
        checked={option.checked}
        onChange={(e) => handleChange(e.target.checked, option)}
        indeterminate={option.indeterminate}
        className="edit-cols-modal-checkbox"
      >
        {option.text}
      </Checkbox>
    );
  });

  const selectAllOpt = options.filter((opt) => opt.text === 'Select All')[0];

  return (
    <React.Fragment>
      <ActionModal
        isHidden={!editModalOpen}
        title="Edit Columns"
        confirmText="Apply"
        onConfirm={() => {
          updateUser();
          setEditModalOpen(false);
        }}
        cancelText="Cancel"
        onCancel={() => setEditModalOpen(false)}
        onClose={() => setEditModalOpen(false)}
        variant="success"
      >
        <p>Select which columns you would like to view or hide, and drag & drop the columns to sort as you see fit</p>
        <Checkbox
          key={selectAllOpt.value}
          checked={selectAllOpt.checked}
          onChange={(e) => handleChange(e.target.checked, selectAllOpt)}
          indeterminate={selectAllOpt.indeterminate}
          className="edit-cols-modal-checkbox"
        >
          {selectAllOpt.text}
        </Checkbox>
        <SortableList
          className="blx-scrollable"
          onDrop={handleOrderChange}
        >
          {sortableOpts}
        </SortableList>
      </ActionModal>
      <button
        className="edit-columns-button"
        onClick={() => setEditModalOpen(true)}
      >
        <p>Edit Columns</p>
        <Icon iconName="menu" />
      </button>
    </React.Fragment>
  );
};

export default EditColumnsButton;

EditColumnsButton.propTypes = {
  tableConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isHidden: PropTypes.bool,
      isPermanent: PropTypes.bool
    })
  )
};

EditColumnsButton.defaultProps = {
  tableConfig: [],
};
