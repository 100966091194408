import React, { useState } from 'react';
import './PipelineQuickView.scss';
import DealInfo from './DealInfo';
import OverridesHistory from './OverridesHistory';
import DIQAuditHistory from './DIQAuditHistory';

const { Tabs, RadioTabItem } = require('@cbrebuild/blocks-react').Tabs;

const PipelineQuickView = () => {
  const [selectedTab, setSelectedTab] = useState('details');
  return (
    <React.Fragment>
      <Tabs>
        <RadioTabItem
          key="horizontal-tabs-1"
          name="quickview-tabs"
          value="details"
          checked={selectedTab === 'details'}
          onChange={(e) => setSelectedTab(e.target.value)}
          text="Details"
        />
        <RadioTabItem
          key="horizontal-tabs-2"
          name="quickview-tabs"
          value="overrides-history"
          checked={selectedTab === 'overrides-history'}
          onChange={(e) => setSelectedTab(e.target.value)}
          text="Performance IQ History"
        />
        <RadioTabItem
          key="horizontal-tabs-3"
          name="quickview-tabs"
          value="diq-history"
          checked={selectedTab === 'diq-history'}
          onChange={(e) => setSelectedTab(e.target.value)}
          text="Deal IQ History"
        />
      </Tabs>
      <div className="tabs-card">
        <section>
          {selectedTab === 'details' && (
            <DealInfo />
          )}
          {selectedTab === 'overrides-history' && (
            <OverridesHistory />
          )}
          {selectedTab === 'diq-history' && (
            <DIQAuditHistory />
          )}
        </section>
      </div>
    </React.Fragment>
  );
};

export default PipelineQuickView;
