import React, { useContext } from 'react';

import Switch from '../Switch';
import API from '../../services/API';
import UserContext from '../../context/UserContext';
import { trackGroupToggle } from '../../services/event-tracking/profile-tracking';
import './Preferences.scss';

const { Dropdown } = require('@cbrebuild/blocks-react').Dropdowns;

const Preferences = () => {
  const { user, setUser } = useContext(UserContext);

  const handleChange = async (isChecked, group) => {
    const response = await API.post('/api/groups/', { [group]: isChecked });
    setUser(response.data);
    trackGroupToggle(isChecked, group);
  };

  const handleRangeChange = async (evt) => {
    const payload = { expiring_lease_email_range: evt.value };
    const response = await API.patch('/api/update_user/', payload);
    setUser(response.data);
  };

  const handleFrequencyChange = async (evt) => {
    const payload = { expiring_lease_email_freq: evt.value };
    const response = await API.patch('/api/update_user/', payload);
    setUser(response.data);
  };

  return (
    <React.Fragment>
      <section className="notification-preferences">
        <h3>Notification Preferences</h3>
        <p>
          Performance IQ will send you email notifications about upcoming lease
          expirations that require action in your market. You can set the time range
          and cadence of these emails below.
        </p>
        <div className="flex-row mb1">
          <span>Include leases that expire in less than:</span>
          <Dropdown
            text="range"
            value={user.expiring_lease_email_range}
            onChange={handleRangeChange}
            options={[
              { value: 90, text: '3 Months' },
              { value: 180, text: '6 Months' },
              { value: 365, text: '12 Months' },
              { value: 730, text: '24 Months' },
              { value: 1095, text: '36 Months' },
            ]}
          />
        </div>
        <div className="flex-row">
          <span>Send me an email notification:</span>
          <Dropdown
            text="frequency"
            value={user.expiring_lease_email_freq}
            onChange={handleFrequencyChange}
            options={[
              { value: 'weekly', text: 'Every Week' },
              { value: 'fortnightly', text: 'Every Two Weeks' },
              { value: 'monthly', text: 'Monthly' },
              { value: 'never', text: 'Never' },
            ]}
          />
        </div>
      </section>
      <h3>Labs</h3>
      <p>Want to try the latest features before anyone else?</p>
      {/* Bleeding Edge Logic */}
      {/* <section className="bleeding-edge-toggle">
        <Switch
          checked={user.user_groups.includes('bleeding_edge')}
          label="Bleeding Edge"
          onChange={isChecked => handleChange(isChecked, 'bleeding_edge')}
        />
      </section> */}
      <section className="labs-toggle">
        <Switch
          checked={user.user_groups.includes('labs')}
          label="Enable Labs"
          onChange={(isChecked) => handleChange(isChecked, 'labs')}
          disabled={user.user_groups.includes('labs')}
        />
      </section>
    </React.Fragment>
  );
};

export default Preferences;
