import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './Flyout.scss';
import { Icon } from '@cbrebuild/blocks';

const Flyout = React.forwardRef(({
  align,
  closeOnInsideClick,
  flyoutContent,
  onOpen,
  onClose,
  trigger,
  triggerIcon,
  triggerText,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const flyoutContentRef = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const onDocumentClick = (event) => {
      if (flyoutContentRef && flyoutContentRef.current) {
        const wasClickInsideFlyout = flyoutContentRef.current.contains(event.target);

        if (isOpen && !wasClickInsideFlyout) {
          setIsOpen(false);
          onClose();
        }
      }
    };

    document.addEventListener('click', onDocumentClick);

    // eslint-disable-next-line consistent-return
    return () => { document.removeEventListener('click', onDocumentClick); };
  }, [isOpen, onClose]);

  const toggleIsOpen = () => {
    if (!isOpen) {
      onOpen();
    }
    setIsOpen(!isOpen);
  };

  const triggerElement = trigger || (
    <div className="blx-dropdown-wrapper">
      <div className="blx-dropdown">
        <button className={`blx-dropdown-trigger${isOpen ? ' blx-active' : ''}`}>
          <Icon iconName={triggerIcon} />
          <span className="blx-dropdown-text">{triggerText}</span>
          <Icon iconName="chevron-down" />
        </button>
      </div>
    </div>
  );

  const alignmentStyle = align === 'right' ? { right: '0px' } : { left: '0px' };

  return (
    <div
      className="flyout"
      onClick={toggleIsOpen}
      role="button"
      ref={ref}
    >
      {triggerElement}
      {isOpen && (
        <div
          className="flyout-content"
          onClick={(evt) => { if (!closeOnInsideClick) { evt.stopPropagation(); } }}
          ref={flyoutContentRef}
          role="button"
          style={alignmentStyle}
        >
          {flyoutContent}
        </div>
      )}
    </div>
  );
});

export default Flyout;

Flyout.propTypes = {
  align: PropTypes.oneOf(['right', 'left']),
  closeOnInsideClick: PropTypes.bool,
  flyoutContent: PropTypes.shape({}).isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  trigger: PropTypes.shape({}),
  triggerIcon: PropTypes.string, // class name of icon
  triggerText: PropTypes.string,
};

Flyout.defaultProps = {
  align: 'left',
  closeOnInsideClick: false,
  onOpen: () => { },
  onClose: () => { },
  trigger: null,
  triggerIcon: null,
  triggerText: null,
};
