/* global
    CONFIG
*/

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';
import PipelineDownloadModal from './PipelineDownloadModal';
import './FileDownloadButton.scss';
import UserContext from '../../context/UserContext';
import PipelineContext from '../../context/PipelineContext';
import { getCookie } from '../../utils/fetch-utils';

const { BaseModal } = require('@cbrebuild/blocks-react').Modals;

const BASE_URL = CONFIG.apiBaseURL;

const FileDownloadButton = ({
  url,
  getDownloadName,
  isPipeline,
  filterParams,
  isPipelineLoading
}) => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [pipelineDownloadModalOpen, setPipelineDownloadModalOpen] = React.useState(false);
  const [isEmailSentModalOpen, setIsEmailSentModalOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { totalCount, filteredSelectedCount } = useContext(PipelineContext);

  const downloadFile = async (urlChunk) => {
    setIsDownloading(true);

    // derive deals count to determine whether to send results via download or email
    const size = urlChunk.indexOf('with_filter') > -1 ? filteredSelectedCount : totalCount;
    const csrftoken = getCookie('csrftoken');

    const requestData = {
      params: filterParams,
      size
    };

    fetch(`${BASE_URL}/api/download_file/${urlChunk}/`,
      { method: 'POST',
        credentials: 'include',
        body: JSON.stringify(requestData),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        }
      })
      .then((response) => {
        const contentType = response.headers.get('content-type');
        if (contentType === 'application/json') {
          response.json().then((json) => {
            if (json.email_sent) {
              setIsEmailSentModalOpen(true);
            }
          });
        } else {
          // download Excel file
          response.blob().then((data) => {
            const blob = new Blob([data], { type: contentType });
            const href = window.URL.createObjectURL(blob);
            const hiddenFile = document.createElement('a');
            hiddenFile.href = href;
            hiddenFile.download = getDownloadName();
            hiddenFile.click();
          });
        }
        setIsDownloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {isPipeline && (
        <PipelineDownloadModal
          downloadFile={downloadFile}
          isOpen={pipelineDownloadModalOpen}
          close={() => setPipelineDownloadModalOpen(false)}
        />
      )}
      <button
        className="download-file"
        onClick={() => {
          if (isPipeline) {
            setPipelineDownloadModalOpen(true);
          } else {
            downloadFile(url);
          }
        }}
        // Disable if already downloading or if pipeline data is still updating (i.e. "Loading...")
        disabled={isDownloading || isPipelineLoading}
      >
        <p>{isDownloading ? 'Loading...' : 'Download'}</p>
        <Icon iconName="download" />
      </button>
      <BaseModal
        isHidden={!isEmailSentModalOpen}
        onClose={() => { setIsEmailSentModalOpen(false); }}
      >
        <div className="blx-modal-title">
          <h4> Pipeline Report Sent</h4>
        </div>
        <p>Your pipeline report was sent to {user.email}</p>
      </BaseModal>
    </React.Fragment>
  );
};

export default FileDownloadButton;

FileDownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
  getDownloadName: PropTypes.func.isRequired,
  isPipeline: PropTypes.bool,
  isPipelineLoading: PropTypes.bool,
  filterParams: PropTypes.shape({}),
};

FileDownloadButton.defaultProps = {
  isPipeline: false,
  isPipelineLoading: false,
  filterParams: {},
};
