import React, { useState, useContext, useRef } from 'react';
import { IconButton, Textarea } from '@cbrebuild/blocks';
import PropTypes from 'prop-types';
import moment from 'moment';
import API from '../../services/API';
import UserContext from '../../context/UserContext';
import './ActionHistory.scss';

const formatLeaseActionMessage = (action, prevAction) => {
  const { state, delegate } = action;
  const prevDelegate = prevAction && prevAction.delegate;
  const hasDelegateChanged =
    delegate && (!prevDelegate || delegate.employee_id !== prevDelegate.employee_id);
  const wasDelegateRemoved = !delegate && prevDelegate;

  let message;
  if (hasDelegateChanged) {
    message = `Delegated to ${delegate.first_name} ${delegate.last_name}`;
  } else if (wasDelegateRemoved) {
    message = 'Undelegated';
  } else {
    message = `Notifications turned ${state === 'active' ? 'on' : 'off'}`;
  }

  return message;
};

// returns true if note.created and note.modified are different by more than a second
const showModifiedDate = (note) => (
  Math.abs(moment(note.created).diff(moment(note.modified))) > 1000
);

// return true if obj is a lease action, returns false if obj is a note
const isLeaseAction = (obj) => (obj.state !== undefined);

const ActionHistory = ({ actions, notes, refetchLease }) => {
  const { user } = useContext(UserContext);
  const [editIndex, setEditIndex] = useState(null);
  const [editText, setEditText] = useState('');
  const editNoteRef = useRef(null);

  actions.forEach((action, index) => {
    // eslint-disable-next-line
    action.message = formatLeaseActionMessage(action, actions[index + 1]);
  });

  // combine actions and notes into one array, because product wants
  // both shown in Action History, and sort by date (most recent first)
  const actionsAndNotes = actions.concat(notes).sort((a, b) => {
    // use "modified" date for actions and "created" date for notes,
    // as all actions on a lease have the same "created" date
    const dateA = isLeaseAction(a) ? a.modified : a.created;
    const dateB = isLeaseAction(b) ? b.modified : b.created;
    return moment(dateB).diff(moment(dateA));
  });

  const editNote = (note, index) => {
    setEditText(note.body);
    setEditIndex(index);
  };

  const saveEdit = async () => {
    if (!editText) {
      return;
    }

    const note = actionsAndNotes[editIndex];

    setEditIndex(null);

    if (note.body === editText) {
      // no changes from original, don't send API request
      return;
    }

    const payload = {
      id: note.id,
      body: editText,
    };

    await API.patch('/api/expiring_leases/notes/', payload);
    refetchLease();
  };

  const deleteAction = async (note) => {
    await API.delete(`api/expiring_leases/notes/delete/${note.id}/`);
    refetchLease();
  };

  const renderLeaseAction = (action) => (
    <tr key={action.modified}>
      <td>{moment(action.modified).format('ll')}</td>
      <td>{moment(action.modified).format('HH:mm')}</td>
      <td>
        <div>{action.user.first_name} {action.user.last_name}</div>
        <div className="subtext">{action.user.job_title}</div>
      </td>
      <td>{action.message}</td>
    </tr>
  );

  const renderNote = (note, index) => (
    <tr key={note.created}>
      <td>{moment(note.created).format('ll')}</td>
      <td>{moment(note.created).format('HH:mm')}</td>
      <td>
        <div>{note.user.first_name} {note.user.last_name}</div>
        <div className="subtext">{note.user.job_title}</div>
      </td>
      <td>
        {
          index !== editIndex ? (
            <React.Fragment>
              <div className="flex-row space-between">
                <div className="subtext">Added a note:</div>
                {(note.user.employee_id === user.employee_id)
                  &&
                (
                <div className="flex-row">
                  <IconButton iconName="edit" onClick={() => { editNote(note, index); }} variant="primary" />
                  <span className="has-margin-left">
                    <IconButton iconName="bin" onClick={() => { deleteAction(note); }} variant="primary" />
                  </span>
                </div>
                )}
              </div>
              <div className="note-body">{note.body}</div>
              {showModifiedDate(note)
                && <div className="subtext">Modified: {moment(note.modified).format('lll')}</div>}
            </React.Fragment>
          )
            : (
              <React.Fragment>
                <Textarea
                  autoFocus
                  value={editText}
                  onChange={(e) => { setEditText(e.target.value); }}
                  onBlur={saveEdit}
                  ref={editNoteRef}
                  rows={{
                    min: 2,
                    max: Infinity,
                  }}
                />
                {!editText && <div className="subtext error">Note cannot be empty.</div>}
              </React.Fragment>
            )
        }
      </td>
    </tr>
  );

  return (
    <div className="card action-history">
      <h4>Action History</h4>
      <table>
        <colgroup>
          <col name="Date" width="15%" />
          <col name="Time" width="15%" />
          <col name="User" width="25%" />
          <col name="Action" width="45%" />
        </colgroup>
        <tbody>
          <tr>
            <th className="label blx-ui-small-caps">Date</th>
            <th className="label blx-ui-small-caps">Time</th>
            <th className="label blx-ui-small-caps">User</th>
            <th className="label blx-ui-small-caps">Action</th>
          </tr>
          {actionsAndNotes.map(
            (obj, index) => (isLeaseAction(obj) ? renderLeaseAction(obj) : renderNote(obj, index))
          )}
          {!actionsAndNotes.length && (
            <tr>
              <td colSpan="4">No actions to date.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ActionHistory;

ActionHistory.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetchLease: PropTypes.func.isRequired,
};
