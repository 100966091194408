import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import BreadCrumbs from '../components/BreadCrumbs';
import ExpiringLease from '../components/ExpiringLease';
import BrokerTeam from '../components/BrokerTeam';
import API from '../services/API';
import { formatLeases } from '../utils/lease-utils';
import ActionHistory from '../components/ActionHistory';
import LeaseNotes from '../components/LeaseNotes';

// returns employees who are brokers on lease
// (some non brokers included in API response.brokers, must exclude these)
const formatBrokers = (employees, lease) => {
  // sometimes lead broker is not in deal line
  const brokerIdsOnLease = new Set([lease._source.lead_broker]);
  lease._source.dealline.forEach((commission) => {
    // account for bad data we've encountered
    if (commission.emplid && commission.emplid !== ' ') {
      brokerIdsOnLease.add(commission.emplid);
    }
  });

  return employees.filter((employee) => (brokerIdsOnLease.has(employee.employee_id)));
};

// attach associated user object to each action or note
const formatActionsOrNotes = (objects, employees) => {
  return objects.map((obj) => {
    const userObject = employees.find((employee) => (employee.employee_id === obj.user));

    return {
      ...obj,
      user: userObject,
    };
  });
};

const LeaseDetail = () => {
  const [lease, setLease] = useState(null);
  const [brokers, setBrokers] = useState([]);
  const [actions, setActions] = useState([]);
  const [notes, setNotes] = useState([]);
  const { id: paramsId } = useParams();

  const fetchLease = useCallback(async () => {
    const { data: responseData } = await API.get(`/api/expiring_leases/${paramsId}/`);
    responseData.deals = { hits: { hits: [responseData.deal] } };

    const formattedLease = formatLeases(responseData)[0];
    setLease(formattedLease);

    const formattedBrokers = formatBrokers(responseData.brokers.data.searchEmployeeDetailsES.employee, formattedLease);
    setBrokers(formattedBrokers);

    const formattedActions =
      formatActionsOrNotes(responseData.actions, responseData.brokers.data.searchEmployeeDetailsES.employee);
    setActions(formattedActions);

    const formattedNotes = formatActionsOrNotes(responseData.notes, responseData.brokers.data.searchEmployeeDetailsES.employee);
    setNotes(formattedNotes);
  }, [paramsId]);

  useEffect(() => {
    fetchLease();
  }, [fetchLease]);

  if (!lease) { return null; }

  const { _id: leaseId, _source: leaseSource } = lease;

  const breadCrumbs = [
    {
      route: '/',
      name: 'Dashboard',
    },
    {
      route: '/expiring-leases/',
      name: 'Lease Expirations'
    },
    {
      route: `/expiring-leases/detail/${leaseId}/`,
      name: 'Lease Detail'
    },
  ];

  return (
    <React.Fragment>
      <BreadCrumbs crumbs={breadCrumbs} />
      <h2>Voucher ID {leaseId}</h2>
      <ExpiringLease
        lease={leaseSource}
        isDetailView
        refetchLease={fetchLease}
      />
      <div className="flex-row">
        <div style={{ width: '33.333%', marginRight: '10px' }}>
          <BrokerTeam
            leadBrokerId={leaseSource.lead_broker}
            brokers={brokers}
          />
          <LeaseNotes
            leaseId={leaseId}
            refetchLease={fetchLease}
          />
        </div>
        <div style={{ width: '66.667%' }}>
          <ActionHistory
            actions={actions}
            notes={notes}
            refetchLease={fetchLease}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeaseDetail;
