import { trackEvent } from './event-tracker';

const eventCategory = 'Profile';

const trackGroupToggle = (isEnabled, group) => {
  const eventData = {
    eventAction: isEnabled ? `profile_${group}_enable` : `profile_${group}_disable`,
    eventCategory,
  };

  trackEvent(eventData);
};

export {
  trackGroupToggle,
};
