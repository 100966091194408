import { FILTER_ATTR_TYPES } from '../constants/filters';

const getAppliedFiltersForTags = (params, filters) => {
  const appliedFilters = [];
  filters.forEach(({ id, attr, attrType, label, hideTagLabel, options }) => {
    const tagLabel = hideTagLabel ? '' : label;

    switch (attrType) {
      case FILTER_ATTR_TYPES.MIN_MAX: {
        const minValue = params[attr[0]];
        const maxValue = params[attr[1]];
        if (minValue || maxValue) {
          appliedFilters.push({ id, tagLabel, minValue, maxValue });
        }
        break;
      }
      case FILTER_ATTR_TYPES.MULTIPLE_CHOICE: {
        if (params[attr]) {
          const valueArray = params[attr].split(',');
          const displayValue = valueArray.length ? `Selected (${valueArray.length})` : 'Select';
          appliedFilters.push({ id, tagLabel, displayValue });
        }
        break;
      }
      case FILTER_ATTR_TYPES.DEFAULT: {
        const value = params[attr];
        if (value) {
          const displayValue = options.find((o) => o.value === value).text;
          appliedFilters.push({ id, tagLabel, displayValue });
        }
        break;
      }
      default: {
        throw new Error('Unrecognized filter attribute type', attrType);
      }
    }
  });

  return appliedFilters;
};

// convert component returned value into query param value
// returns params object
// { attr: string || null }
const getUpdatedParamValueForAttr = (attr, attrType, newValue) => {
  const newParams = {};

  switch (attrType) {
    case FILTER_ATTR_TYPES.MULTIPLE_CHOICE: {
      newParams[attr] = newValue.length ? newValue.join(',') : null;
      break;
    }
    default:
      newParams[attr] = newValue || null;
  }
  return newParams;
};

const getValueFromFilterInput = (evtOptOrVal) => {
  // an event was passed back from component (Toggle or NumberTextField)
  if (evtOptOrVal.target) return evtOptOrVal.target.value;

  // an object with value was passed back from component (Dropdown)
  if (evtOptOrVal.value) return evtOptOrVal.value;

  // value was passed back directly from component (Datepicker, MultiSelect)
  return evtOptOrVal;
};

const getEmptyParamsForFilter = ({ attr, attrType }) => {
  const emptyParams = {};
  switch (attrType) {
    case FILTER_ATTR_TYPES.MIN_MAX: {
      emptyParams[attr[0]] = null;
      emptyParams[attr[1]] = null;
      break;
    }
    case FILTER_ATTR_TYPES.MULTIPLE_CHOICE:
    case FILTER_ATTR_TYPES.DEFAULT: {
      emptyParams[attr] = null;
      break;
    }
    default: {
      throw new Error('Unrecognized filter attribute type', attrType);
    }
  }
  return emptyParams;
};

const getEmptyParamsForFilters = (filters) => {
  let allEmptyParams = {};
  filters.forEach((filter) => {
    const emptyParams = getEmptyParamsForFilter(filter);
    allEmptyParams = { ...allEmptyParams, ...emptyParams };
  });

  return allEmptyParams;
};

const addSelectAllForMultiSelect = ((options, selectAllText) => {
  const optionsCopy = options;
  const validOptions = optionsCopy.filter((option) => !option.isGroupHeader);

  // filter component take option.value as string
  optionsCopy.unshift({
    key: 'all',
    value: (validOptions.map((option) => option.value)).join(','),
    text: selectAllText || 'Select All',
    isGroupHeader: false,
    isNested: false,
  });
  return optionsCopy;
});

export {
  getAppliedFiltersForTags,
  getUpdatedParamValueForAttr,
  getEmptyParamsForFilter,
  getEmptyParamsForFilters,
  addSelectAllForMultiSelect,
  getValueFromFilterInput,
};
