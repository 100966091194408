import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import './SearchInput.scss';
import { Icon, IconButton } from '@cbrebuild/blocks';

const { TextField } = require('@cbrebuild/blocks-react').Input;

const SearchInput = ({
  onChange,
  placeholder,
  value,
}) => {
  // duplicate value prop (from parent state) as internal state
  // so that internal state can change immediately as user types
  // and parent state can be updated with a debounce to prevent extraneous API calls
  const [searchText, setSearchText] = useState(value);

  const clearInput = () => {
    setSearchText('');
    onChange('');
  };

  const debouncedOnChange = useCallback(debounce(onChange, 250), [onChange]);

  const onInputChange = (e) => {
    setSearchText(e.target.value);
    debouncedOnChange(e.target.value);
  };

  return (
    <div className="search-input">
      <Icon iconName="magnifier" />
      <TextField
        type="text"
        value={searchText || ''}
        onChange={onInputChange}
        placeholder={placeholder}
      />
      {searchText && (
        <IconButton
          iconName="close-circle"
          onClick={clearInput}
          variant="basic"
        />
      )}
    </div>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

// TODO: yes?
SearchInput.defaultProps = {
  placeholder: '',
  value: '',
};

export default SearchInput;
