/* global CONFIG */
const { intercomId } = CONFIG;

const initIntercom = (user) => {
  if (
    !window.Intercom
    || !user.can_access_perf_iq
    || !user.has_accepted_terms_of_service
    || user.is_hijacked
  ) {
    return;
  }

  const intercomData = {
    app_id: intercomId,
    name: `${user.first_name} ${user.last_name}`,
    user_id: user.id,
    email: user.email,
    widget: {
      activator: '.intercom-trigger',
    },
    hide_default_launcher: true,
  };

  window.Intercom('boot', intercomData);
};

export default initIntercom;
