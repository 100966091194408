import React, { useState, useContext } from 'react';

import BreadCrumbs from '../components/BreadCrumbs';
import UserContext from '../context/UserContext';
import API from '../services/API';
import './GenerateReports.scss';
const { TextField } = require('@cbrebuild/blocks-react').Input;

const GenerateReports = () => {
  const { user } = useContext(UserContext);
  const [recipients, setRecipients] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState('');
  const [emailValidation, setEmailValidation] = useState('');

  const breadCrumbs = [
    {
      route: '/',
      name: 'Dashboard',
    },
    {
      route: '/generate-reports/',
      name: 'Generate Reports'
    }
  ];

  const validateEmails = (recipientsList) => {
    return recipientsList.map(
      (recipient) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(recipient);
      }
    )
  };

  const onSubmit = async () => {
    setEmailValidation('');
    setResponse('');
    const recipientsList = recipients.split(',').map(email => email.trim());
    if (!validateEmails(recipientsList).every(Boolean)) {
      return setEmailValidation('Please enter a valid email');
    }
    setIsSubmitting(true);
    const payload = {
      recipients: recipientsList,
    };

    await API.post('api/send_report/send_national_pipeline_report/', payload)
      .then((res) =>
        res.data.email_sent && setResponse('Sent email successfully'),
      )
      .catch((err) => {
        setResponse(err.response.data)
      });
      setIsSubmitting(false);
  };

  if (!user.can_access_generate_reports) {
    return (
      <section className="card profile-card">
        <h4>You don't have permission to view this page, please contact an administrator </h4>
      </section>
    )
  }

  return (
    <div>
      <BreadCrumbs crumbs={breadCrumbs} />
      <section className="card profile-card">
        <div className="field-row">
          <label>Send National Pipeline Report:</label>
          <div className="field-container">
            <TextField
              type="text"
              value={recipients}
              onChange={(e) => {
                setEmailValidation('');
                setRecipients(e.target.value);
              }}
              placeholder={'Add email address. Multiple emails separated by a comma'}
            />
            <small style={{whiteSpace: 'nowrap'}}>Add email address. Multiple emails separate with a comma</small>
          </div>
        </div>
        &emsp;
        &emsp;
        <div >
          <button
            className="blxs-button blxs-button-primary"
            disabled={!recipients || isSubmitting || emailValidation}
            onClick={() => onSubmit()}
          >
            Submit
          </button>
        </div>
        &emsp;
        &emsp;
        &emsp;
        <div style={{ whiteSpace: 'nowrap'}}>{response}</div>
        <div style={{ whiteSpace: 'nowrap', color: '#e70909'}}>{emailValidation}</div>
      </section>
    </div>
  );
};

export default GenerateReports;
