import React from 'react';
import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';
import classnames from 'classnames';
import './multiSelectItem.scss';
import { Checkbox, IconButton } from '@cbrebuild/blocks';

const MultiSelctDropdownItem = React.forwardRef((props, ref) => {
  const {
    option,
    onSelect,
    onKeyDown,
    onKeyUp,
    selected,
    isGroupOpen,
    toggleGroup,
  } = props;
  const values = option.value.split(',');
  const intersections = intersection(selected, values);
  const checked = intersections.length === values.length;
  const indeterminate = intersections.length && intersections.length < values.length;

  const handleClick = (e) => {
    e.preventDefault();
    onSelect(e, option);
  };

  const toggleButton = () => {
    const iconClassName = isGroupOpen ? 'chevron-down' : 'chevron-right';
    const handleGroupToggleClick = () => {
      toggleGroup(option.groupKey);
    };

    return (
      <IconButton
        className="group-toggle"
        iconName={iconClassName}
        onClick={handleGroupToggleClick}
        variant="basic"
        tabIndex="0"
      />
    );
  };

  // ignores spacebar onKeyUp and onKeyDown for checkbox accessibility
  const filterKeyStroke = (callback) => (e) => e.which !== 32 ? callback(e) : undefined;

  return (
    <li
      className={classnames('blx-dropdown-item', 'multi-select-item', {
        'nested-option': option.isNested,
        hidden: option.isNested && !isGroupOpen,
      })}
      ref={ref}
    >
      {option.isGroupHeader && toggleButton()}
      <button
        className={classnames('option-item', {
          'select-all': option.key === 'all',
        })}
        title={option.text}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onClick={(e) => handleClick(e, option)}
      >
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onKeyDown={filterKeyStroke(onKeyDown)}
          onKeyUp={filterKeyStroke(onKeyUp)}
          onChange={(e) => handleClick(e, option)}
        />
        <span className="option-text">
          {option.text}
          <span className="subtext">{option.subtext ? option.subtext : ''}</span>
        </span>
      </button>
    </li>
  );
});

MultiSelctDropdownItem.propTypes = {
  option: PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    subtext: PropTypes.string,
    groupKey: PropTypes.string, // for nested select
    isGroupHeader: PropTypes.bool, // for nested select
    isNested: PropTypes.bool, // for nested select
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  isGroupOpen: PropTypes.bool, // for nested select
  toggleGroup: PropTypes.func, // for nested select
};

MultiSelctDropdownItem.defaultProps = {
  isGroupOpen: true,
  toggleGroup: () => {},
};

export default MultiSelctDropdownItem;
