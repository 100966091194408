/* global CONFIG */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import '../node_modules/@cbrebuild/blocks-base/dist/blocks.css';
import '../node_modules/@cbrebuild/blocks-base/dist/variables.sass';
import '@cbrebuild/blocks/dist/styles/components.css';
// Global Styles
import './App.scss';
import './Shared.scss';
import { Icon } from '@cbrebuild/blocks';

import TopNav from './components/TopNav';
import UserMenu from './components/UserMenu';
import HijackBanner from './components/HijackBanner';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import PermissionDenied from './pages/PermissionDenied';
import ExpiringLeases from './pages/ExpiringLeases';
import LeaseDetail from './pages/LeaseDetail';
import Profile from './pages/Profile';
import Pipeline from './pages/Pipeline';
import GenerateReports from './pages/GenerateReports';
import TermsOfService from './pages/TermsOfService';
import Privacy from './pages/Privacy';
import UserContext from './context/UserContext';
import userService from './services/user-service';
import { storeEmployeeId, trackPageView } from './services/event-tracking/event-tracker';
import initIntercom from './services/intercom-service';
import fullstoryIdentify from './services/fullstory-service';

const pageTitles = {
  '/expiring-leases/': 'Expiring Leases',
  '/login/': 'Login',
  '/profile/': 'Profile',
  '/privacy/': 'Privacy',
  '/terms/': 'Terms of Service'
};

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const initUser = async () => {
      const userData = await userService.fetchUser();
      if (userData) {
        const { data } = userData;
        setUser(data);
        storeEmployeeId(data.employee_id);
        initIntercom(data);
        fullstoryIdentify(data);
      } else {
        // It will fall in this block when server is not running.
        // if server runs, the userData will come in with all keys
        // even when it is not authenticated. See `serialize_user()` in BE serializers.py.
        // TODO: handle errors better
        console.log('Server Error');
      }
    };

    initUser();
  }, []);

  if (!user) {
    return null;
  }

  // pretend user has accepted ToS when user is hijacked, to prevent redirect to ToS
  const acceptedTerms = user.has_accepted_terms_of_service || user.is_hijacked;

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <Route
          path="/file_download/:key"
          render={(key) => (
            window.location = `${CONFIG.apiBaseURL}${key.location.pathname}${key.location.search}`
          )}
        />
        <Route
          path="/"
          render={(props) => {
            document.title = (pageTitles[props.location.pathname]) ?
              `Performance IQ - ${pageTitles[props.location.pathname]}` :
              'Performance IQ';

            // render fires even when location didn't change
            trackPageView({ pathname: props.location.pathname });
            return null;
          }}
        />
        <div className="App">
          <header className="app-header">
            <div className="flex-row space-between align-center">
              <div className="flex-row">
                {user.can_access_perf_iq && acceptedTerms &&
                  <TopNav />}
                <h3>Performance IQ</h3>
              </div>
              {(user.email && user.can_access_perf_iq && acceptedTerms) && (
                <div className="header-controls">
                  <Icon className="intercom-trigger" iconName="message" />
                  <UserMenu user={user} />
                </div>
              )}
            </div>
            {user.is_hijacked && <HijackBanner />}
          </header>
          <section className={`content${user.is_hijacked ? ' showing-hijack-banner' : ''}`}>
            <div className="page">
              <Switch>
                <Route path="/" exact component={Dashboard} />
                <Route path="/expiring-leases/" exact component={ExpiringLeases} />
                <Route path="/expiring-leases/detail/:id" component={LeaseDetail} />
                <Route path="/pipeline/" component={Pipeline} />
                <Route path="/generate-reports/" component={GenerateReports} />
                <Route path="/login/" component={Login} />
                <Route path="/profile/" component={Profile} />
                <Route path="/terms/" component={TermsOfService} />
                <Route path="/privacy/" component={Privacy} />
                <Route path="/denied/" component={PermissionDenied} />
              </Switch>
            </div>
            {!user.email && <Redirect to="/login/" />}
            {(user.email && !user.can_access_perf_iq) && <Redirect to="/denied/" />}
            {(user.email && user.can_access_perf_iq && !acceptedTerms) && (
              <Redirect to="/terms/" />
            )}
          </section>
        </div>
      </Router>
    </UserContext.Provider>
  );
};

export default App;

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

App.defaultProps = {
  location: {
    pathname: '',
  },
};
