import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import pipelineService from '../../services/pipeline-service';

import PipelineContext from '../../context/PipelineContext';
import { dateFormat, currencyFormat, percentFormat } from '../../utils/formatters';
import { extractModifiedByName } from '../../utils/object-utils';

const NO_VALUE = '--';

const OverridesInfo = () => {
  const {
    quickViewData: { deal_iq_id: dealId },
  } = useContext(PipelineContext);
  const [overrides, setOverrides] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    pipelineService.fetchOverridesHistory(dealId)
      .then((res) => {
        setOverrides(res);
        setIsLoading(false);
      })
      .catch(() => setError(true));
  }, []);

  const overrideKeyToDisplay = (override, index) => {
    const keyMap = {
      conversion_potential_override: ['Added Conversion Potential Override of ', (val) => percentFormat(val)],
      installment_date_override: ['Added Installment Date Override of ', (val) => dateFormat(val)],
      total_commission_to_cbre_override: ['Added CBRE Gross Commission Override of ', (val) => currencyFormat(val)],
      is_on_pipeline_override: ['Selected in Performance IQ: ', () => {}],
      manager_comments: ['Added Manager Comments: ', (val) => (val)]
    };

    const key = override.override_key;
    if (keyMap[key]) {
      const displayDate = override.modified ? moment( override.modified).format('M/D/YYYY') : null;
      const displayTime = override.modified ? moment( override.modified).format('h:mm A') : null;
      const displayKey = keyMap[key][0];
      const displayName = override.modified_by ? extractModifiedByName(override.modified_by) : null;

      let displayVal;
      if (key === 'is_on_pipeline_override') {
        // don't count default "yes" as an override for is_on_pipeline_override
        if (!override.modified) displayVal = NO_VALUE;
        else {
          displayVal = override.value === 'True' ? 'Yes' : 'No';
        }
      } else {
        displayVal = override.value ? keyMap[key][1](override.value) : NO_VALUE;
      }

      return (
        <tr key={`${override.id}- ${index}`}>
          <td>{displayDate}</td>
          <td>{displayTime}</td>
          <td>{displayName}</td>
          <td>{`${displayKey}${displayVal}`}</td>
        </tr>
      );
    }
    return null;
  };

  return (
    <div className="card action-history">
      <div className="subtext">
        All times are displayed in your local time zone.
      </div>
      <table>
        <colgroup>
          <col name="Date" width="10%" />
          <col name="Time" width="15%" />
          <col name="User" width="25%" />
          <col name="Action" width="50%" />
        </colgroup>
        <tbody>
          <tr>
            <th className="label blx-ui-small-caps">Date</th>
            <th className="label blx-ui-small-caps">Time</th>
            <th className="label blx-ui-small-caps">User</th>
            <th className="label blx-ui-small-caps">Action</th>
          </tr>
          {isLoading
            && (
              <tr>
                <td colSpan="4">Loading, please wait...</td>
              </tr>
            )}
          {!isLoading && !error && overrides && overrides.length
            ? (
              overrides.map(
                (override, index) => overrideKeyToDisplay(override, index)
              )
            ) : null}
          {!isLoading && !overrides.length && (
            <tr>
              <td colSpan="4">
                {error
                  ? 'An unexpected error occurred'
                  : 'No overrides info.'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OverridesInfo;
