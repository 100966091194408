import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { intersection, difference } from 'lodash';
import classnames from 'classnames';
import withToggle from '../wrapper/toggle';
import MultiSelectItem from './MultiSelectItem';
import SearchInput from '../SearchInput/index';

import './multiSelect.scss';

const { DownIcon } = require('@cbrebuild/blocks-react').Icons;
const { keyControlledMenu } = require('@cbrebuild/blocks-react').wrappers;

const MultiSelectDropdown = (props) => {
  const {
    id,
    label,
    value,
    text,
    options,
    optionsRefs,
    isOpen,
    disabled,
    isValid,
    onChange,
    toggle,
    onKeyDown,
    onKeyUp,
    onTriggerFocus,
    invalidErrorMessage,
    selectedplaceholder,
    forwardedRef,
    searchMultiSelect
  } = props;

  const [triggerText, setTriggerText] = useState(text);
  const [openGroups, setOpenGroups] = useState([options]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredOptionsRefs, setFilteredOptionsRefs] = useState([]);

  useEffect(() => {
    setFilteredOptions(options);
    setFilteredOptionsRefs(optionsRefs);
  }, [options, optionsRefs]);

  useEffect(() => {
    const selectedCount = value.length;
    if (selectedCount) {
      setTriggerText(`${selectedplaceholder || 'Selected'} (${selectedCount})`);
    } else {
      setTriggerText(text);
    }
  }, [value, options, text, selectedplaceholder]);

  const triggerClassNames = classnames('blx-dropdown-trigger', {
    'blx-active': isOpen,
    'blx-disabled': disabled,
    'blx-invalid': !isValid,
  });

  const getSelectedValues = (option) => {
    // this checks if the option is already selected.
    // If the option is not selected before, It adds the option to the value array.
    // If the option is already selected, it removes the option from the value array.
    const valuesCopy = [...value];
    const optionValues = option.value.split(',');
    const intersections = intersection(valuesCopy, optionValues);
    const differences = difference(valuesCopy, optionValues) || [];

    if (intersections.length) {
      if (intersections.length === optionValues.length) {
        return differences;
      }
      return differences.concat(optionValues);
    }

    return valuesCopy.concat(optionValues);
  };

  const handleSelect = (e, option) => {
    const newSelectedValueArray = getSelectedValues(option);
    onChange(newSelectedValueArray);
  };

  const toggleGroup = (groupKey) => {
    const openGroupsCopy = [...openGroups];
    const foundIndex = openGroupsCopy.indexOf(groupKey);
    if (foundIndex > -1) {
      openGroupsCopy.splice(foundIndex, 1);
    } else {
      openGroupsCopy.push(groupKey);
    }
    setOpenGroups(openGroupsCopy);
  };

  const handleKeyUp = (e, option) => {
    // intercepts keycontrol and do proper selection on enter key stroke.
    if (e.keyCode === 13) {
      handleSelect(e, option);
    } else {
      onKeyUp(e);
    }
  };

  const filterOptionsBySearch = (input) => {
    const search = input.toLowerCase();

    if (search === '') {
      setOpenGroups([]);
      setFilteredOptions(options);
      setFilteredOptionsRefs(optionsRefs);
    } else {
      const groupKeys = [];

      const newOptions = options.filter((opt) => {
        if (opt.key === 'all') return true;
        if (opt.isGroupHeader) {
          groupKeys.push(opt.groupKey);
          return true;
        }
        const lowerCaseOpt = opt.text.toLowerCase();
        return lowerCaseOpt.includes(search);
      });
      setFilteredOptions(newOptions);

      const newOptionsRefs = [];
      options.forEach((opt, idx) => {
        if (newOptions.includes(opt)) newOptionsRefs.push(optionsRefs[idx]);
      });
      setFilteredOptionsRefs(newOptionsRefs);

      setOpenGroups(groupKeys);
    }
  };

  return (
    <div
      className="blx-dropdown-wrapper multiselect"
      id={id}
      ref={forwardedRef}
    >
      <div className="blx-dropdown">
        {/* LABEL */}
        {label && (
          <label htmlFor="trigger" className={`blx-ui-text ${disabled ? 'blx-disabled' : ''}`}>
            {label}
          </label>
        )}

        {/* TRIGGER */}
        <button
          id="trigger"
          className={triggerClassNames}
          disabled={disabled}
          onClick={toggle}
          title={triggerText}
          onKeyDown={onKeyDown}
          onFocus={onTriggerFocus}
        >
          <span
            className={
              value.length ? 'blx-dropdown-text' : 'blx-dropdown-placeholder'
            }
          >
            {triggerText}
          </span>
          <DownIcon className="blx-dropdown-arrow" />
        </button>

        {/* DROPDOWN MENU */}
        <div
          className={classnames('blx-dropdown-menu', {
            'blx-hidden': !isOpen,
          })}
        >
          {searchMultiSelect && (
            <div className="search-row">
              <SearchInput
                className="multi-select-search"
                placeholder="Search for producers"
                onChange={filterOptionsBySearch}
              />
            </div>
          )}
          <ul
            className="blx-dropdown-list, blx-scrollable"
          >
            {filteredOptions.map((option, idx) => (
              <MultiSelectItem
                key={`${option.value}_${option.text}`}
                option={option}
                ref={filteredOptionsRefs[idx]}
                selected={value}
                onKeyDown={(e) => onKeyDown(e, option)}
                onKeyUp={(e) => handleKeyUp(e, option)}
                onSelect={handleSelect}
                isGroupOpen={openGroups.includes(option.groupKey)}
                toggleGroup={toggleGroup}
              />
            ))}
          </ul>
        </div>
      </div>
      {/* INVALID MESSAGE */}
      {!isValid && (
        <span className="blx-invalid-input-message">
          {invalidErrorMessage}
        </span>
      )}
    </div>
  );
};

MultiSelectDropdown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  text: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      subtext: PropTypes.string,
      groupKey: PropTypes.string, // for nested select
      isGroupHeader: PropTypes.bool, // for nested select
      isNested: PropTypes.bool, // for nested select
    })
  ),
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  toggle: PropTypes.func.isRequired, // from toggle wrapper
  onKeyDown: PropTypes.func.isRequired, // from keycontrol wrapper
  onKeyUp: PropTypes.func.isRequired, // from keycontrol wrapper
  onTriggerFocus: PropTypes.func.isRequired, // from keycontrol wrapper
  optionsRefs: PropTypes.array.isRequired, // from key control wrapper
  invalidErrorMessage: PropTypes.string,
  selectedplaceholder: PropTypes.string,
  forwardedRef: PropTypes.shape({}),
  searchMultiSelect: PropTypes.bool,
};

MultiSelectDropdown.defaultProps = {
  id: '',
  label: '',
  value: [],
  text: 'Select',
  options: [],
  isOpen: false,
  disabled: false,
  isValid: true,
  onChange: () => {},
  invalidErrorMessage: null,
  selectedplaceholder: '',
  forwardedRef: null,
  searchMultiSelect: false,
};

export default withToggle(keyControlledMenu(MultiSelectDropdown));
