/*
  This component wrapper handle open and close wrapped component.
  It closes the component when outer area is clicked, but
  it doesn't close itself when inner area is clicked.
*/

import React, { useState, useRef } from 'react';

// eslint-disable-next-line react/prop-types
const withToggle = (WrappedComponent) => ({ isOpen: defaultIsOpen = false, ...props }) => {
  const [isOpen, setIsopen] = useState(defaultIsOpen);
  const ref = useRef(null);

  function closeOnClick(e) {
    if (!ref.current) {
      return;
    }

    if (ref.current.contains(e.target)) {
      return;
    }
    setIsopen(false);
    document.body.removeEventListener('click', closeOnClick);
  }

  const open = () => {
    setIsopen(true);
    document.body.addEventListener('click', closeOnClick);
  };

  const close = (e) => {
    setIsopen(false);
    document.body.removeEventListener('click', closeOnClick);
    e.stopPropagation();
  };

  const toggle = (e) => {
    if (isOpen) {
      close(e);
    } else {
      open();
    }
  };

  return (
    <WrappedComponent
      {...props}
      forwardedRef={ref}
      isOpen={isOpen}
      toggle={toggle}
      open={open}
      close={close}
    />
  );
};

export default withToggle;
