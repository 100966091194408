import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';

import SearchInput from '../SearchInput/index';
import FilterFlyout from '../FilterFlyout';
import FilterTags from '../FilterTags';
import FileDownloadButton from '../FileDownloadButton';
import EditColumnsButton from '../EditColumnsButton';

import './ControlBar.scss';

const { Dropdown } = require('@cbrebuild/blocks-react').Dropdowns;

const ControlBar = ({
  searchProps,
  filterProps,
  sortProps,
  editColsProps,
  downloadProps,
  filterTagProps,
  children,
}) => {
  return (
    <React.Fragment>
      <div className={`control-bar flex-row ${searchProps ? 'space-between' : 'flex-end'}`}>
        {searchProps && <SearchInput {...searchProps} />}
        <div className="control-buttons flex-row">
          {children && children}
          {filterProps && <FilterFlyout {...filterProps} />}
          {sortProps && <Dropdown text="Sort" icon={<Icon iconName="sort" />} {...sortProps} />}
          {editColsProps && <EditColumnsButton {...editColsProps} />}
          {downloadProps && <FileDownloadButton {...downloadProps} filterParams={filterTagProps.params} />}
        </div>
      </div>
      {filterTagProps && <FilterTags {...filterTagProps} />}
    </React.Fragment>
  );
};

export default ControlBar;

ControlBar.propTypes = {
  searchProps: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired
  }),
  supFilterProps: PropTypes.shape({
    params: PropTypes.shape({}),
    applyParams: PropTypes.func,
    filters: PropTypes.array,
  }),
  filterProps: PropTypes.shape({
    params: PropTypes.shape({}),
    applyParams: PropTypes.func,
    filters: PropTypes.array,
  }),
  sortProps: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
      })
    ).isRequired
  }),
  editColsProps: PropTypes.shape({
    tableConfig: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        isHidden: PropTypes.bool,
        isPermanent: PropTypes.bool
      })
    )
  }),
  downloadProps: PropTypes.shape({
    url: PropTypes.string,
    size: PropTypes.number,
    isPipelineLoading: PropTypes.bool,
    filterParams: PropTypes.shape({}),
  }),
  filterTagProps: PropTypes.shape({
    params: PropTypes.shape({}),
    applyParams: PropTypes.func,
    filters: PropTypes.array,
  }),
  children: PropTypes.node,
};

ControlBar.defaultProps = {
  searchProps: null,
  supFilterProps: null,
  filterProps: null,
  sortProps: null,
  editColsProps: null,
  downloadProps: null,
  filterTagProps: null,
  children: null,
};
