const getParamsFromURL = (defaultParams = {}) => {
  const paramsObject = { ...defaultParams };

  const queryParams = new URLSearchParams(window.location.search);
  for (const [key, value] of queryParams) {
    paramsObject[key] = value;
  }

  return paramsObject;
};

const storeParamsToURL = (params) => {
  const serializedParams = Object.entries(params)
    .filter((arr) => arr[1] !== null)
    .map((arr) => arr.join('='))
    .join('&');

  // Use window.location.pathname to clear old query params if there are no new ones
  const newState = serializedParams ? `?${serializedParams}` : window.location.pathname;
  window.history.replaceState({}, '', newState);
};

export {
  getParamsFromURL,
  storeParamsToURL
};
