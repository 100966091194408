/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import get from 'lodash/get';

import { Button, Icon, IconButton } from '@cbrebuild/blocks';
import API from '../../services/API';
import {
  trackNotificationToggle, trackDelegation
} from '../../services/event-tracking/expiring-leases-tracking';
import EmployeeAutocomplete from '../EmployeeAutocomplete';
import LabeledItem from '../LabeledItem';
import './ExpiringLease.scss';

const ExpiringLease = ({ lease, leadBroker, isDetailView, refetchLease }) => {
  const [isAssigningDelegate, setIsAssigningDelegate] = useState(false);
  const delegate = lease.lastAction && lease.lastAction.delegate;
  const status = (lease.lastAction && lease.lastAction.state) || 'active';
  const statusIconClass = status === 'active' ? 'alert' : 'alert-off';

  let lessee;
  let lessor;
  let propertyOwner;

  if (lease.lease) {
    lease.lease.company_contact_role_addresses.forEach((role) => {
      switch (role.role_desc) {
        case 'Lessor':
          lessor = role;
          break;
        // Lessees / tenants can show up as either
        case 'Tenant':
          lessee = role;
          break;
        case 'Lessee':
          lessee = role;
          break;
        case 'Legal Owner':
          propertyOwner = role;
          break;
        default:
          break;
      }
    });
  }

  const repRole = lease.cbre_rep_client === 'L' ? 'landlord' : 'tenant';
  const client = repRole === 'landlord' ? lessor : lessee;
  const lesseeLabel = repRole === 'landlord' ? 'Lessee' : 'Lessee *';
  const lessorLabel = repRole === 'landlord' ? 'Lessor *' : 'Lessor';

  const delegateEmployee = async (employee) => {
    const payload = {
      deal_id: lease.deal_id,
      delegate: employee.employee_id,
      state: 'dismissed',
    };

    await API.post('/api/expiring_leases/delegate/', payload);
    trackDelegation(employee.employee_id, lease.deal_id);
    refetchLease();
  };

  const clearDelegate = async () => {
    setIsAssigningDelegate(false);

    const payload = {
      deal_id: lease.deal_id,
      delegate: '',
      state: 'active',
    };

    await API.post('/api/expiring_leases/delegate/', payload);
    refetchLease();
  };

  const toggleStatus = async () => {
    const nextStatus = status === 'active' ? 'dismissed' : 'active';
    const delegateId = delegate ? delegate.employee_id : '';
    const payload = {
      deal_id: lease.deal_id,
      delegate: delegateId,
      state: nextStatus,
    };

    await API.post('/api/expiring_leases/delegate/', payload);
    trackNotificationToggle(nextStatus, lease.deal_id);
    refetchLease();
  };

  const getLeadBroker = () => {
    if (leadBroker) {
      return (
        <React.Fragment>
          <div>{leadBroker.first_name} {leadBroker.last_name}</div>
          <div className="subtext">{leadBroker.job_title}</div>
          <div className="subtext">{leadBroker.work_email}</div>
          <div className="subtext">{leadBroker.location_name}</div>
        </React.Fragment>
      );
    }

    return '--';
  };

  return (
    <div className={`card expiring-lease${isDetailView ? ' is-detail-view' : ''}`}>
      <div className="flex-row space-between mb1">
        { isDetailView && (
          <h3>{client ? client.company_name : 'Missing Company Name'}</h3>
        )}
        { !isDetailView && (
          <NavLink to={`/expiring-leases/detail/${lease.deal_id}/`}>
            <h3>{lease.deal_id}</h3>
          </NavLink>
        )}
        <span className={`rep-role-pill lease-${repRole}`}>lease — {repRole}</span>
      </div>
      <div className="flex-row flex-wrap relative lease-info">
        <LabeledItem
          label={lesseeLabel}
          item={lessee && lessee.company_name}
        />
        <LabeledItem
          label={lessorLabel}
          item={lessor && lessor.company_name}
        />
        <LabeledItem
          label="Start"
          format="utc"
          item={get(lease, 'lease.arrangement_start_date')}
        />
        <LabeledItem
          label="Expiration"
          format="utc"
          item={get(lease, 'lease.arrangement_end_date')}
        />
        <LabeledItem
          label="Delegate"
          item={(
            <React.Fragment>
              {delegate && (
                <React.Fragment>
                  <div className="flex-row align-center">
                    <div>{delegate.first_name} {delegate.last_name}</div>
                    <IconButton
                      iconName="close-circle"
                      onClick={clearDelegate}
                      variant="basic"
                    />
                  </div>
                  <div className="subtext">{delegate.job_title}</div>
                </React.Fragment>
              )}
              {!delegate && (
                <React.Fragment>
                  {isAssigningDelegate && (
                    <EmployeeAutocomplete
                      onSelect={delegateEmployee}
                    />
                  )}
                  {!isAssigningDelegate && (
                    <div
                      className="text-button"
                      onClick={() => {
                        setIsAssigningDelegate(true);
                      }}
                      role="button"
                    >
                      + Assign Delegate
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        />
        {!isDetailView && (
          <React.Fragment>
            <LabeledItem
              label="Lead Producer"
              item={getLeadBroker()}
            />
            {lease.searchMatchedProducer && (
              <LabeledItem
                label="Team Producer"
                item={(
                  <React.Fragment>
                    <div>
                      {`${lease.searchMatchedProducer.first_name}
                      ${lease.searchMatchedProducer.last_name}`}
                    </div>
                    <div className="subtext">{lease.searchMatchedProducer.job_title}</div>
                  </React.Fragment>
                )}
              />
            )}
          </React.Fragment>
        )}
        <LabeledItem
          label="Property"
          itemClass="property-item"
          format="address"
          item={get(lease, 'lease.property.postal_address')}
        />
        <LabeledItem
          label="Property Owner"
          item={propertyOwner && propertyOwner.company_name}
        />
        <LabeledItem
          label="SQFT"
          format="integer"
          item={get(lease, 'lease.total_leased_space')}
        />
        <LabeledItem
          label="Total Commission"
          format="currency"
          item={lease.tot_comm}
        />
        <LabeledItem
          label="Property Type"
          item={get(lease, 'lease.property.property_usage.ref_property_usage_type_desc')}
        />
        <Button
          onClick={toggleStatus}
        ><Icon iconName={statusIconClass} />
        </Button>
      </div>
    </div>
  );
};

export default ExpiringLease;

ExpiringLease.propTypes = {
  isDetailView: PropTypes.bool,
  lease: PropTypes.shape({}).isRequired,
  leadBroker: PropTypes.shape({}),
};

ExpiringLease.defaultProps = {
  isDetailView: false,
  leadBroker: {},
};
