import { trackEvent } from './event-tracker';
import { getDifferences } from '../../utils/object-utils';

const eventCategory = 'Lease Expirations';
let paramsOnOpen;
let numResultsOnOpen;

const trackFilterOpen = (params, numResults) => {
  paramsOnOpen = params;
  numResultsOnOpen = numResults;
};

const trackFilterClose = (params, numResults) => {
  const paramsOnClose = params;
  const differences = getDifferences(paramsOnOpen, paramsOnClose);

  if (!Object.keys(differences).length) {
    return;
  }

  const eventData = {
    eventAction: 'lease_expiration_filter',
    eventCategory,
    customData: {
      filter_diff: JSON.stringify(differences), // need to stringify JSON objects
      before_results: numResultsOnOpen,
      after_results: numResults,
    }
  };

  trackEvent(eventData);
};

const trackSearch = (searchTerm) => {
  if (!searchTerm) {
    return;
  }

  const eventData = {
    eventAction: 'lease_expiration_search',
    eventCategory,
    customData: {
      search_term: searchTerm,
    }
  };

  trackEvent(eventData);
};

const trackNotificationToggle = (status, dealId) => {
  let eventAction = 'lease_expiration_notification_disable';
  if (status === 'active') {
    eventAction = 'lease_expiration_notification_enable';
  }
  const eventData = {
    eventAction,
    eventCategory,
    customData: {
      deal_id: dealId,
    }
  };

  trackEvent(eventData);
};

const trackDelegation = (employeeId, dealId) => {
  const eventData = {
    eventAction: 'lease_expiration_delegate',
    eventCategory,
    customData: {
      employee_id: employeeId,
      deal_id: dealId,
    }
  };

  trackEvent(eventData);
};

export {
  trackFilterOpen,
  trackFilterClose,
  trackSearch,
  trackNotificationToggle,
  trackDelegation,
};
