import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Flyout from '../Flyout';
import Filters from '../Filters';

const FilterFlyout = ({ onOpen, onClose, filters, params, applyParams }) => {
  const flyoutButtonRef = useRef(null);

  return (
    <Flyout
      triggerIcon="filter"
      triggerText="Filter"
      align="right"
      onOpen={onOpen}
      onClose={onClose}
      ref={flyoutButtonRef}
      flyoutContent={(
        <Filters
          filters={filters}
          params={params}
          applyParams={applyParams}
          flyoutButtonRef={flyoutButtonRef}
        />
      )}
    />
  );
};

export default FilterFlyout;

FilterFlyout.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  // TODO: make a sharedPropTypes file
  filters: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
  params: PropTypes.shape({}),
  applyParams: PropTypes.func.isRequired
};

FilterFlyout.defaultProps = {
  onOpen: () => { },
  onClose: () => { },
  params: {}
};
