import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import ControlBar from '../ControlBar';
import { PIPELINE_FILTERS as filters, PIPELINE_SUP_FILTER as supFilter } from '../../constants/filters';
import pipelineService from '../../services/pipeline-service';
import Filters from '../Filters';

const getDownloadName = () => {
  const now = new Date().toISOString(); // YYYY-MM-DDTHH:mm:ss. sssZ
  const formattedNow = now.replace(/-|:|\.|Z/g, '').replace('T', '-'); // YYYYMMDD-HHmmss. sss
  return `perf_iq_pipeline_${formattedNow}.xlsx`;
};

const PipelineControlBar = (props) => {
  const {
    params,
    applyParams,
    tableConfig,
    isPipelineLoading,
  } = props;

  const [brokersFilter, setBrokersFilter] = useState(supFilter);

  const search = debounce((value) => {
    applyParams({ search: value || null });
  }, 1000);

  const controlBarProps = {
    searchProps: {
      value: params.search || '',
      onChange: search,
      placeholder: 'Search for deals'
    },
    filterProps: {
      filters,
      params,
      applyParams
    },
    editColsProps: {
      tableConfig
    },
    downloadProps: {
      url: 'pipeline_excel',
      getDownloadName,
      isPipeline: true,
      isPipelineLoading,
      filterParams: params,
    },
    filterTagProps: {
      filters: [...filters, brokersFilter],
      params,
      applyParams,
    }
  };

  useEffect(() => {
    pipelineService.getFMCOptions()
      .then((res) => {
        supFilter.options = res;
        setBrokersFilter({ ...supFilter });
      });
  }, []);

  return (
    <ControlBar {...controlBarProps}>
      {/* Add extra element in ControlBar */}
      <Filters
        filters={brokersFilter ? [brokersFilter] : null}
        params={params}
        applyParams={applyParams}
        isSingleFilter={true}
      />
    </ControlBar>
  );
};

export default PipelineControlBar;

PipelineControlBar.propTypes = {
  params: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  applyParams: PropTypes.func.isRequired,
  tableConfig: PropTypes.array.isRequired,
  isPipelineLoading: PropTypes.bool.isRequired,
};
