import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import pipelineService from '../../services/pipeline-service';
import PipelineContext from '../../context/PipelineContext';

const DIQAuditHistory = () => {
  const {
    quickViewData: { deal_iq_id: dealId },
  } = useContext(PipelineContext);

  const [auditData, setAuditData] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    pipelineService.fetchDIQAuditInfo(dealId)
      .then((res) => {
        setAuditData(res);
      })
      .catch(() => setError(true));
  }, []);

  // Hacky workaround to remove asterisks around created deal messages.
  // This shouldn't excessively impact performance.
  const renderMessage = (audit) => {
    if (audit.category === 'transaction_created') {
      return audit.message.replace(/deal \*\*/, 'deal "').replace(/\*\*$/, '"');
    }
    return audit.message;
  };

  const renderAction = (audit) => (
    <tr key={audit.created}>
      <td>{moment(audit.created).format('M/D/YYYY')}</td>
      <td>{moment(audit.created).format('h:mm A')}</td>
      <td>
        <div>{audit.information_json.user_name}</div>
      </td>
      <td>{renderMessage(audit)}</td>
    </tr>
  );

  return (
    <div className="card action-history">
      <div className="subtext">
        All times are displayed in your local time zone.
      </div>
      <table>
        <colgroup>
          <col name="Date" width="10%" />
          <col name="Time" width="15%" />
          <col name="User" width="20%" />
          <col name="Action" width="55%" />
        </colgroup>
        <tbody>
          <tr>
            <th className="label blx-ui-small-caps">Date</th>
            <th className="label blx-ui-small-caps">Time</th>
            <th className="label blx-ui-small-caps">User</th>
            <th className="label blx-ui-small-caps">Action</th>
          </tr>
          {!auditData && (
            <tr>
              <td colSpan="4">
                {error ? 'An unexpected error occurred' : 'Loading, please wait...'}
              </td>
            </tr>
          )}
          {auditData && auditData.map((audit) => renderAction(audit))}
          {auditData && !auditData.length && (
            <tr>
              <td colSpan="4">No audit info.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DIQAuditHistory;
