import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import API from '../../services/API';
import './Avatar.scss';

const Avatar = ({ email, firstName, lastName }) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const response = await API.get(`/api/avatar/?employee_email=${email}`);
        setImage(response.data.avatar);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error fetching avatar: ', e);
        setImage('');
      }
    };

    fetchAvatar();
  }, [email]);

  return (
    <div className="avatar">
      {image && (
        <img src={`data:image/png;base64,${image}`} alt={`Avatar for ${firstName} ${lastName}`} />
      )}
      {!image && firstName && lastName && (
        <div className="initials">{`${firstName[0]}${lastName[0]}`}</div>
      )}
      {!image && (!firstName || !lastName) && (
        <div className="initials">ME</div>
      )}
    </div>
  );
};

export default Avatar;

Avatar.propTypes = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

Avatar.defaultProps = {
  firstName: '',
  lastName: '',
};
