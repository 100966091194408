import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { currencyFormat } from '../../utils/formatters';
import './pipelineTotalBoxes.scss';

const PipelineTotalBoxes = (props) => {
  const { aggregates, isLoading } = props;
  const [data, setData] = useState([]);

  const formatAggregatesData = useCallback((aggrObj) => {
    return [
      {
        title: 'Deals Selected',
        selectedCount: aggrObj.number_of_selected_deals,
        totalCount: aggrObj.total_count,
      },
      {
        title: 'Total Gross Commission',
        subTitle: 'to FMC or selected producers',
        total: aggrObj.total_gross_commission_selected,
        emptyState: '- None Selected',
      },
      {
        title: 'Total Adjusted Gross Commission',
        subTitle: 'to FMC or selected producers',
        total: aggrObj.total_gross_commission_adjusted,
        emptyState: '- None Selected',
      },
      {
        title: 'Total CBRE Gross Commission',
        total: aggrObj.total_gross_commission_from_deal_iq,
      },
    ];
  }, []);

  useEffect(() => {
    setData(formatAggregatesData(aggregates));
    return () => {};
  }, [aggregates, formatAggregatesData]);

  const renderCounts = useCallback((selectedCount, totalCount) => {
    return (
      <span>{selectedCount}
        <span className="total-count"> of {totalCount}</span>
      </span>
    );
  }, []);

  const renderTotals = useCallback((total, emptyState) => {
    const formattedTotal = currencyFormat(total, 0);

    const noneSelectedText = <span className="blx-ui-small">{emptyState}</span>;
    return <span>{formattedTotal} {data[0].selectedCount === 0 && noneSelectedText}</span>;
  }, [data]);

  return (
    <div className="total-boxes">
      {data.map((item) => {
        const {
          selectedCount,
          totalCount,
          title,
          subTitle,
          total,
          emptyState,
        } = item;

        const showCount = Object.prototype.hasOwnProperty.call(item, 'selectedCount') &&
          item.selectedCount !== undefined;
        const showTotal = Object.prototype.hasOwnProperty.call(item, 'total');

        return (
          <div className="total-box" key={uniqueId()}>
            <div className="title">
              <div className="blx-ui-small bold">{title}</div>
              {subTitle && <div className="blx-ui-small">{subTitle}</div>}
            </div>
            {!isLoading && (
              <div className="total">
                {showTotal && renderTotals(total, emptyState)}
                {showCount && renderCounts(selectedCount, totalCount)}
              </div>
            )}
            <center>{isLoading && 'Loading...'}</center>
          </div>
        );
      })}
    </div>
  );
};

PipelineTotalBoxes.propTypes = {
  aggregates: PropTypes.shape({
    total_count: PropTypes.number,
    number_of_selected_deals: PropTypes.number,
    total_gross_commission_selected: PropTypes.number,
    total_gross_commission_adjusted: PropTypes.number,
    total_gross_commission_from_deal_iq: PropTypes.number,
  }),
  isLoading: PropTypes.bool.isRequired,
};

PipelineTotalBoxes.defaultProps = {
  aggregates: {
    total_count: 0,
    number_of_selected_deals: 0,
    total_gross_commission_selected: 0,
    total_gross_commission_adjusted: 0,
    total_gross_commission_from_deal_iq: 0,
  },
};

export default PipelineTotalBoxes;
