import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';
import './QuickView.scss';

const QuickView = ({ children, toggleQuickView, show }) => {
  return (
    <div className={show ? 'quick-view show' : 'quick-view hide'}>
      <div onClick={toggleQuickView} className="quick-view-overlay" />
      <div className="quick-view-container" onClick={(e) => e.stopPropagation()}>
        <IconButton
          className="close-icon"
          iconName="close"
          ariant="basic"
          onClick={toggleQuickView}
        />
        {children}
      </div>
    </div>
  );
};

QuickView.propTypes = {
  children: PropTypes.node,
  toggleQuickView: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

QuickView.defaultProps = {
  children: null,
  show: false,
};

export default QuickView;
