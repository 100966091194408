import React from 'react';
import PropTypes from 'prop-types';

import './FilterTag.scss';
import { IconButton } from '@cbrebuild/blocks';

const FilterTag = (props) => {
  const {
    label,
    value,
    minValue,
    maxValue,
    handleClearFilter,
  } = props;

  let filterTagText = label ? `${label}: ` : '';

  if (value) {
    filterTagText += value;
  } else if (minValue && maxValue) {
    filterTagText += `${minValue} - ${maxValue}`;
  } else if (minValue) {
    filterTagText += `> ${minValue}`;
  } else if (maxValue) {
    filterTagText += `< ${maxValue}`;
  }

  return (
    <div className="filter-tag">
      {filterTagText}
      <IconButton
        iconName="close"
        onClick={handleClearFilter}
        variant="basic"
      />
    </div>
  );
};

export default FilterTag;

FilterTag.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  minValue: PropTypes.string,
  maxValue: PropTypes.string,
  handleClearFilter: PropTypes.func.isRequired,
};

FilterTag.defaultProps = {
  label: '',
  value: '',
  minValue: '',
  maxValue: '',
};
