/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import BreadCrumbs from '../components/BreadCrumbs';
import API from '../services/API';
import { formatLeases } from '../utils/lease-utils';
import { currencyFormat } from '../utils/formatters';

const Dashboard = () => {
  const [expiringLeases, setExpiringLeases] = useState([]);
  const [total, setTotal] = useState(0);
  // const [topBrokers, setTopBrokers] = useState([]);

  useEffect(() => {
    API.get('/api/expiring_leases/').then((response) => {
      const formattedLeases = formatLeases(response.data);
      setExpiringLeases(formattedLeases);
    });

    API.get('/api/expiring_leases/count/').then((response) => {
      setTotal(response.data.deals_count);
    });

    /*
      TODO: Comment back in when we're ready to ship the top brokers feature
      API.get('/api/top_brokers/').then((response) => {
        const formattedBrokers = response.data.broker_earnings.aggregations.nested_root.filter_brokers.group_by_employee.buckets.map((earning) => {
          let brokerName = earning.key;
          const broker = response.data.broker_info.hits.hits.find(broker => (broker._id === earning.key));
          if (broker) {
            brokerName = `${broker._source.first_name} ${broker._source.last_name}`;
          }

          return {
            ...earning,
            brokerName,
          };
        });

        setTopBrokers(formattedBrokers)
      });
    */

    // Set Top Brokers to empty array until we're ready to populate the field, to prevent build errors
    // setTopBrokers([]);
  }, []);

  const breadCrumbs = [
    {
      route: '/',
      name: 'Dashboard',
    }
  ];

  return (
    <React.Fragment>
      <BreadCrumbs crumbs={breadCrumbs} />
      <h2>Dashboard</h2>
      <h3>Upcoming Lease Expirations ({total.toLocaleString()})</h3>
      <table className="perf-table">
        <thead>
          <tr>
            <th>Expires</th>
            <th>Property Name</th>
            <th>Customer</th>
            <th>Lead Producer</th>
            <th>Total Commission</th>
          </tr>
        </thead>
        <tbody>
          {expiringLeases.map((lease) => {
            const {
              _id: leaseId,
              _source: leaseSource
            } = lease;
            // eslint-disable-next-line
            const broker = lease.broker;
            const property = leaseSource.lease && leaseSource.lease.property.property_name;
            const expiration = leaseSource.lease && moment.utc(leaseSource.lease.arrangement_end_date).format('ll');
            let lessee;
            let lessor;

            if (leaseSource.lease) {
              leaseSource.lease.company_contact_role_addresses.forEach((role) => {
                switch (role.role_desc) {
                  case 'Lessor':
                    lessor = role;
                    break;
                  case 'Tenant':
                    lessee = role;
                    break;
                  default:
                    break;
                }
              });
            }

            const repRole = leaseSource.cbre_rep_client === 'L' ? 'landlord' : 'tenant';
            const client = repRole === 'landlord' ? lessor : lessee;

            return (
              <tr key={leaseId}>
                <td>{expiration || '—'}</td>
                <td>{property || '—'}</td>
                <td>{client && client.company_name}</td>
                <td>{broker && `${broker.first_name || '—'} ${broker.last_name || ''}`}</td>
                <td>{currencyFormat(leaseSource.tot_comm)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* {TODO: enable this when ready. Disabling Lease expiration temporarily} */}
      {/* <h3>Top Brokers</h3>
      {topBrokers.length && (
        <table className="perf-table">
          <thead>
            <tr>
              <th>Broker</th>
              <th>Gross Commission</th>
            </tr>
          </thead>
          <tbody>
            {topBrokers.map((item) => (
              <tr key={item.key}>
                <td>{item.brokerName}</td>
                <td>{currencyFormat(item.gross_commission.value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!topBrokers.length > 0 && (
        <p className="in-development">
          There is currently no content to be displayed here – engineering
          effort is in process. Please continue to use other available features.
        </p>
      )} */}
    </React.Fragment>
  );
};

export default Dashboard;
