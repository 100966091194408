const getDifferences = (before, after) => {
  const differences = {};

  const allKeys = new Set(Object.keys(before).concat(Object.keys(after)));

  for (const key of allKeys) {
    if (after[key] !== before[key]) {
      differences[key] = {
        from: before[key] || null,
        to: after[key] || null,
      };
    }
  }

  return differences;
};

/*
  A deal's overrides objct always needs to have all four override sub-objects,
  and each needs as a minimum to have a "value" for React table to read.

  If there is already a sub-object for a given key, don't alter it.
  If not, populate the sub-object with an empty 'value' field, indicating no override.

  dealOverrides:: deal.overrides
  return:: copy of new overrides object
*/
const fillOverrides = (dealOverrides) => {
  const NO_OVERRIDE = null;
  const newOverrides = {};
  const hasOverrides = !!dealOverrides;
  const overrideKeys = [
    'conversion_potential_override',
    'installment_date_override',
    'is_on_pipeline_override',
    'manager_comments',
    'total_commission_to_cbre_override'
  ];

  for (const key of overrideKeys) {
    if (!hasOverrides || !dealOverrides[key]) {
      newOverrides[key] = {
        value: NO_OVERRIDE
      };
    } else {
      newOverrides[key] = dealOverrides[key];
    }
  }
  return newOverrides;
};

// Custom Date formatting for UTC strings; use for modified timestamps
const parseDate = (dateStr) => {
  const dateObj = new Date(dateStr);
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };
  return new Intl.DateTimeFormat('en-US', options).format(dateObj);
};

const capitalize = (word) => word[0].toUpperCase() + word.slice(1);

// Assumption: all emails have local part delimited by periods: ex. first.last@cbre.com
const extractModifiedByName = (user) => {
  // Check if user obj has name
  if (user.name) {
    return user.name;
  }
  try {
    const name = user.email.split('@')[0].split('.');
    return name.map((n) => capitalize(n)).join(' ');
  } catch (error) {
    console.log('extractModifiedByName encountered unfamiliar format: ', user.email);
    return user.email;
  }
};

export {
  getDifferences,
  fillOverrides,
  parseDate,
  extractModifiedByName
};
