/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { Button, Checkbox } from '@cbrebuild/blocks';
import UserContext from '../context/UserContext';
import API from '../services/API';
import './TermsOfService.scss';

const TermsOfService = () => {
  const { user, setUser } = useContext(UserContext);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(user.has_accepted_terms_of_service);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const acceptTerms = async () => {
    const payload = { has_accepted_terms_of_service: true };
    const response = await API.patch('/api/update_user/', payload);
    setUser(response.data);
    setShouldRedirect(true);
  };

  if (shouldRedirect) {
    return <Redirect to="/" />;
  }

  return (
    <div className="terms-of-service">
      <h2>Terms of Service</h2>
      <div className="card">
        <div className="scroll-box">
          <h5>(EFFECTIVE AS OF JANUARY 9, 2017) PLEASE READ CAREFULLY</h5>
          <p>This Confidential Information and Nondisclosure Agreement (“Agreement”) is entered into by and between CBRE, Inc., a Delaware corporation (“CBRE”), its subsidiaries, affiliates, successors, or assigns (together the “Company”) and me. As a condition of using the Performance IQ Market Builder Software (“Performance IQ”), I agree to the following terms:</p>
          <h5>CONFIDENTIALITY</h5>
          <h5>1.1. Confidential Information</h5>
          <p>1.1.1 I understand and acknowledge that by using Performance IQ, I will have access to and learn about confidential, secret and proprietary documents, materials and other information, in tangible and intangible form, of and relating to the Company and its prior, existing and prospective clients, investors, employees and other associated third parties, including but not limited to outside brokers, vendors, tenants, and contractors (“Confidential Information”). I further understand and acknowledge that this Confidential Information and the Company’s ability to reserve it for the exclusive knowledge and use of the Company is of great competitive importance and commercial value to the Company, and that improper use or disclosure of Confidential Information by me might cause the Company to incur financial costs, loss of business advantage, liability under confidentiality agreements with third parties, civil damages and/or criminal penalties.</p>
          <p>1.1.2. For purposes of this Agreement, Confidential Information includes, but is not limited to, all information available on Performance IQ, regardless of form or format, not generally known to the public, related directly or indirectly to: agreements, deals, contracts, leases, sales, transactions, negotiations, offers, demands, portfolios, land, properties, repairs, construction, rent, pricing, tenants, vendors, landlords, sellers, buyers, clients, outside brokers, financing, rebates, fee sharing, installments, accounting, credit, debt, legal information, advertising, designs, payrolls, staffing, personnel, compensation, commissions, employee lists, employee information, including identities, talents, skills, commissions, compensation, and experience of employees, developments, reports, images, graphics, maps, drawings, charts, sketches, market studies, revenue, costs, formulae, notes, communications, algorithms, product plans, property plans, designs, business processes, practices, methods, policies, tasks, plans, documents, research, operations, services, strategies, forecasts, goals, predictions, pictures, techniques, computer programs, computer software, applications, and operating systems of Company or any existing or prospective client, investor, employee and other associated third party, including but not limited to outside brokers, vendors and contractors, or of any other person or entity that has entrusted information to the Company in confidence.</p>
          <p>1.1.3. I understand that the above list is not exhaustive, and that Confidential Information also includes other information on Performance IQ that appears to a reasonable person to be confidential or proprietary in the context and circumstances in which the information is known or used. Confidential Information does not include any information that (i) was publicly known and made generally available in the public domain prior to the time the Company disclosed the information to me on Performance IQ, (ii) became publicly known and made generally available, after disclosure to me by the Company on Performance IQ, through no wrongful action or inaction by me or by others who were under confidentiality obligations, or (iii) was in my rightful possession, without confidentiality restrictions, prior to or at the time of disclosure by the Company on Performance IQ, as shown by my files and records.</p>
          <h5>1.2. Acknowledgement; Nondisclosure and Use Restrictions; Information of Third Parties.</h5>
          <p>1.2.1. I acknowledge and agree that all Confidential Information and other documentation related to the business of the Company or the business of the Company’s clients or third parties whether or not containing any Confidential Information shall be the sole and exclusive property of the Company or, in an appropriate case, the exclusive property of the Company’s clients or third parties.</p>
          <p>1.2.2. Except only in the performance of my authorized duties on behalf of Company and for Company’s benefit, I covenant and agree (i) not to directly or indirectly disclose, publish, communicate or make available Confidential Information, or allow it to be disclosed, published, communicated or made available, in whole or part, to any entity or person whatsoever (including other employees of the Company) not having a need to know and authority to know and use the Confidential Information in connection with the business of the Company and, in any event, not to anyone outside of the direct employ of the Company except as required in the performance of my authorized employment duties to the Company and then, such disclosure shall be made only within the limits and to the extent of such duties or consent; (ii) not to access or use any Confidential Information, and not to print or copy any documents, records, files, media or other resources containing any Confidential Information, or remove any such documents, records, files, media or other resources from the premises or control of the Company, except as required in the performance of my authorized employment duties to the Company or with the prior consent of an authorized officer acting on behalf of the Company in each instance (and then, such disclosure shall be made only within the limits and to the extent of such duties or consent); and (iii) not to use any Confidential Information for my personal benefit or the benefit of any other individual, company, agency, or entity.</p>
          <p>1.2.3. I covenant and agree that I shall treat all Confidential Information as strictly confidential and take all measures and precautions necessary to prevent any unauthorized use or disclosure of Confidential Information not authorized by this Agreement.</p>
          <p>1.2.4. I agree that I shall not disclose to the Company, use in the course of my employment, or upload or save to Performance IQ any information of a third party where such use or disclosure would be in breach of any obligation I owe to such third party.</p>
          <p>1.2.5. I understand that my unauthorized use or disclosure of Confidential Information during my employment may lead to disciplinary action, up to and including immediate termination, and legal action by the Company.</p>
          <p>1.2.6. Nothing herein shall be construed to prevent disclosure of Confidential Information as may be required by applicable law or regulation, or pursuant to a valid order of a court of competent jurisdiction or an authorized government agency, provided that the disclosure does not exceed the extent of such disclosure required by such law, regulation or order. I will promptly provide written notice of any such order to an authorized officer of the Company within 3 days of receiving such order, but in any event sufficiently in advance of making any disclosure to permit the Company to contest the order to seek confidentiality protections, as determined by the Company’s sole discretion. I acknowledge that the Defend Trade Secrets Act (“DTSA”) provides civil and criminal immunity for any disclosure of Trade Secrets and Confidential Information to my attorney, the government, or in a court filing under seal, so long as the purpose is for reporting or investigating a suspected violation of the law. I further acknowledge that if I file a lawsuit for retaliation by virtue of reporting a suspected violation of the law, I may use Trade Secrets and Confidential Information in that anti-retaliation lawsuit. In addition, I acknowledge that this Section does not, in any way, restrict or impede me from exercising my rights under Section 7 of the National Labor Relations Act, from filing a charge with or participating in any investigation or proceeding conducted by the Equal Employment Opportunity Commission, National Labor Relations Board, or a comparable state or local agency or otherwise disclosing information as permitted by law.</p>
          <h5>1.3. Duration of Confidentiality Obligations; Return.</h5>
          <p>1.3.1. I understand and acknowledge that my obligations under this Agreement with regard to any particular Confidential Information shall commence immediately upon first having access to Performance IQ and shall forever continue during and after my employment with Company or until such time as such Confidential Information has become public knowledge other than as a result of my breach of this Agreement or breach by those acting in concert with me or on my behalf.</p>
          <p>1.3.2. I covenant and agree that I shall return (and will not keep in my possession, recreate or deliver to anyone else) all Confidential Information immediately upon the termination of my employment with Company or upon request by the Company. This obligation shall include the return of any materials, whether written, electronic or in any other form, including any summaries, copies, excerpts, notes or memoranda containing or relating to any Confidential Information.</p>
          <h5>2. COMPANY PROPERTY. I ACKNOWLEDGE THAT PERFORMANCE IQ IS THE COMPANY’S PROPRIETARY TECHNOLOGY PLATFORM AND I HAVE NO EXPECTATION OF PRIVACY EITHER IN INFORMATION IN TRANSIT THROUGH THE COMPANY’S TECHNOLOGY PLATFORMS OR STORED ON COMPANY TECHNOLOGY PLATFORMS. ALL INFORMATION, DATA, REPORTS, IMAGES, AND MESSAGES CREATED, RECEIVED, SENT, DOWNLOADED, UPLOADED, OR STORED ON PERFORMANCE IQ ARE, AT ALL TIMES, THE PROPERTY OF THE COMPANY. AS SUCH, THE COMPANY HAS THE RIGHT TO AUDIT AND SEARCH ALL SUCH INFORMATION, WITHOUT FURTHER NOTICE TO ME, TO ENSURE COMPLIANCE WITH THE COMPANY’S POLICIES AND FOR ANY OTHER BUSINESS-RELATED PURPOSES IN THE COMPANY’S SOLE DISCRETION. THE COMPANY RESERVES THE RIGHT TO ACCESS, REVIEW, COPY, AND DELETE ANY OF THE INFORMATION, DATA, REPORTS, IMAGES OR MESSAGES ACCESSED THROUGH PERFORMANCE IQ WITH OR WITHOUT NOTICE TO ME. THE COMPANY MAY REVIEW PLATFORM ACTIVITY AND ANALYZE USAGE PATTERNS TO ASSURE THAT TECHNOLOGY SYSTEMS ARE DEVOTED TO LEGITIMATE BUSINESS PURPOSES.</h5>
          <h5>3. REMEDIES. I ACKNOWLEDGE THAT THE COMPANY’S CONFIDENTIAL INFORMATION AND THE COMPANY’S ABILITY TO RESERVE IT FOR THE EXCLUSIVE KNOWLEDGE AND USE OF THE COMPANY IS OF GREAT COMPETITIVE IMPORTANCE AND COMMERCIAL VALUE TO THE COMPANY, AND THAT IMPROPER USE OR DISCLOSURE OF THE CONFIDENTIAL INFORMATION BY ME WILL CAUSE IRREPARABLE HARM TO THE COMPANY, FOR WHICH REMEDIES AT LAW WILL NOT BE ADEQUATE. IN THE EVENT OF A BREACH OR THREATENED BREACH BY ME OF ANY OF THE PROVISIONS OF THIS AGREEMENT, I HEREBY CONSENT AND AGREE THAT THE COMPANY SHALL BE ENTITLED TO SEEK, IN ADDITION TO OTHER AVAILABLE REMEDIES, A TEMPORARY OR PERMANENT INJUNCTION OR OTHER EQUITABLE RELIEF AGAINST SUCH BREACH OR THREATENED BREACH FROM ANY COURT OF COMPETENT JURISDICTION, WITHOUT THE NECESSITY OF SHOWING ANY ACTUAL DAMAGES OR THAT MONETARY DAMAGES WOULD NOT AFFORD AN ADEQUATE REMEDY, AND WITHOUT THE NECESSITY OF POSTING ANY BOND OR OTHER SECURITY. THE AFOREMENTIONED EQUITABLE RELIEF SHALL BE IN ADDITION TO, NOT IN LIEU OF, LEGAL REMEDIES, MONETARY DAMAGES OR OTHER AVAILABLE FORMS OF RELIEF, INCLUDING THOSE UNDER THE DTSA.</h5>
          <h5>MISCELLANEOUS</h5>
          <p>4.1. Modification and Waiver. No provision of this Agreement may be amended or modified unless such amendment or modification is agreed to in writing and signed by me and a duly authorized officer of the Company. The waiver by either party of a breach of any term of provision of this Agreement shall not be construed as a waiver of any subsequent breach of the same or any other term or provision by either party.</p>
          <p>4.2. Severability. Should any provision of this Agreement be declared or be determined by any court of competent jurisdiction to be illegal, invalid, or unenforceable, the legality, validity, and enforceability of the remaining parts, terms, or provisions shall not be affected thereby, and each remaining provision of this Agreement shall be valid and enforceable to the fullest extent permitted by law.4.3. Assignment of Agreement. Neither this Agreement nor any rights under it may be assigned by me without the prior written consent of the Company. The Company may assign this Agreement to any successor-in-interest, whether by merger, consolidation, sale, or transfer, of all or a substantial portion of the Company’s assets, or otherwise.</p>
          <p>4.3. Binding Effect. This Agreement shall be binding upon and operate to the benefit of the parties and their respective heirs, successors, legal and personal representatives, and permitted assigns.</p>
          <p>4.4. Applicable Law. This Agreement shall be governed by and construed in accordance with the laws of my principal place of business at the time I execute this Agreement.</p>
          <p>4.5 Survival. The provisions of this Agreement shall survive my employment relationship with the Company and the assignment of this Agreement by the Company to any successor in interest or other assignee.</p>
          <h5>5. ENTIRE AGREEMENT. THIS AGREEMENT CONTAINS THE FINAL AND EXCLUSIVE AGREEMENT AND UNDERSTANDING OF THE PARTIES WITH RESPECT TO THE SUBJECT MATTER OF IT, AND SUPERSEDES ALL PRIOR AND CONTEMPORANEOUS AGREEMENTS, ORAL OR WRITTEN. EXCEPT AS SET FORTH IN THIS AGREEMENT, THERE ARE NO PROMISES, REPRESENTATIONS, AGREEMENTS OR UNDERSTANDINGS, ORAL OR WRITTEN, AMONG THE PARTIES RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT; PROVIDED, HOWEVER, THAT MY AT-WILL EMPLOYMENT AGREEMENT, IF ANY, SHALL REMAIN IN FULL FORCE AND EFFECT.</h5>
        </div>
        <div className="flex-row justify-center align-center">
          <Checkbox
            checked={hasAcceptedTerms}
            onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
          >
            I accept these terms.
          </Checkbox>
          <Button
            onClick={acceptTerms}
            disabled={!hasAcceptedTerms}
          >Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
