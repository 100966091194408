/* eslint-disable camelcase */
const currencyFormat = (number, fractionDigits) => {
  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  };

  let formattedNumber = new Intl.NumberFormat('en-US', options).format(number);
  if (formattedNumber === '-$0') formattedNumber = '$0'; // "-$0" not allowed
  return formattedNumber;
};

const percentFormat = (number) => {
  const options = {
    style: 'percent',
  };
  return new Intl.NumberFormat('en-US', options).format(number / 100);
};

const integerFormat = (number) => {
  const options = {
    style: 'decimal',
    maximumFractionDigits: 0,
  };

  return new Intl.NumberFormat('en-US', options).format(number);
};

const numberFormat = (number, fraction = 0) => {
  const options = {
    style: 'decimal',
    maximumFractionDigits: fraction,
  };

  return new Intl.NumberFormat('en-US', options).format(number);
};

const addressFormat = (propertyObject) => {
  if (!propertyObject) {
    return '--';
  }

  const { full_street_name, city, state_province, postal_code } = propertyObject;

  let addressLineOne = full_street_name || '';
  const addressLineTwoArr = [city, state_province, postal_code].filter((item) => item);
  const addressLineTwo = addressLineTwoArr.length ? addressLineTwoArr.join(', ') : '';

  if (addressLineOne && addressLineTwo) {
    addressLineOne = `${addressLineOne}\n`;
  }

  const fullAddress = addressLineOne + addressLineTwo;

  return fullAddress || '--';
};

const dateFormat = (dateString) => {
  if (Number.isNaN(Date.parse(dateString))) return null; // if dateString is incorrectly formatted or is null return null
  return new Date(dateString).toLocaleDateString('en-US', { timeZone: 'UTC' });
};

export {
  currencyFormat,
  integerFormat,
  addressFormat,
  dateFormat,
  numberFormat,
  percentFormat,
};
